import React from "react";
import images from "../../constants/images";
import CareerJoin from "./components/CareerJoin";
import CurrentOpenings from "./components/CurrentOpenings";
import { Helmet } from "react-helmet";

const Careers = () => {
  // const scrollRef = useRef(null);
  // useScrollSnap({ ref: scrollRef, duration: 100, delay: 50 });
  return (
    <>
      <Helmet>
        <title>Invontec | Careers</title>
        <link rel="canonical" href="https://www.invontec.com/career" />
      </Helmet>
      <div className="overflow-hidden">
        <div className="  flex justify-center items-center  flex-col gap-5 overflow-hidden">
          <div className="w-full">
            <img
              src={images.careerBg}
              alt="about"
              className="w-full h-auto"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="500"
              style={{ width: "100%", height: "200px" }}
            />
          </div>

          <div className="flex justify-center items-center  ">
            <CareerJoin />
          </div>
          <div
            className="flex justify-center items-center flex-col "
            id="current-opening"
          >
            <div className="flex flex-col items-center  bg-white ">
              <div className="text-center " data-aos="fade-down">
                <h1 className="text-5xl font-semibold font-share-tech ">
                  Current&nbsp;
                  <span className=" tracking-wide">OPENINGS</span>
                </h1>
                <p
                  className="  text-gray-700 text-xl lg:tracking-normal tracking-wide"
                  data-aos="fade-up-left"
                >
                  Browse our current openings that suits you the best
                </p>
              </div>
            </div>
          </div>
          <CurrentOpenings />
        </div>
      </div>
    </>
  );
};

export default Careers;
