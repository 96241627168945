import React from 'react';
import HeroSection from './components/HeroSection/HeroSection';
import AboutUs from './components/AboutUs/AboutUs';
import OurServices from './components/OurServices/OurServices';
import MakeInTransition from './components/MakeInTransition/MakeInTransition';
import OurCredential from './components/OurCredential/OurCredential';
import Solutions from './components/Solutions/Solutions';
const Home = () => {
  return (
    <div className="overflow-x-hidden">
      <HeroSection />
      <AboutUs />
      <OurServices />
      <MakeInTransition />
      <OurCredential />
      <Solutions />
    </div>
  );
};

export default Home;
