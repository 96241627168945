import React from 'react';
import './AboutUs.css';
import AboutCard from '../../../../UI/Cards/AboutCard/AboutCard';
import { FaRegThumbsUp, FaBoxOpen } from 'react-icons/fa';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { MdMiscellaneousServices } from 'react-icons/md';
import { GoGraph } from 'react-icons/go';
import { GiDiamondTrophy } from 'react-icons/gi';

const AboutUs = () => {
  const data = [
    {
      title: 'Centers of Excellence',
      color: '#29a8de',
      subtitle: '3+',
      icon: <FaRegThumbsUp color="white" fontSize={25} />,
    },
    {
      title: 'Projects Completed',
      subtitle: '20+',
      color: '#29a8de',
      icon: <FaBoxOpen color="white" fontSize={25} />,
    },
    {
      subtitle: '150+',
      title: 'Strong Workforce',
      color: '#29a8de',
      icon: <HiOutlineUserGroup color="white" fontSize={25} />,
    },
    {
      subtitle: '10+',
      title: 'Services Offered',
      color: '#29a8de',
      icon: <MdMiscellaneousServices color="white" fontSize={25} />,
    },
    {
      subtitle: '6+',
      title: 'SAP Services',
      color: '#29a8de',
      icon: <GoGraph color="white" fontSize={25} />,
    },
    {
      subtitle: '12+',
      title: 'Industry Verticals',
      color: '#29a8de',
      icon: <GiDiamondTrophy color="white" fontSize={25} />,
    },
  ];
  return (
    <div className="flex flex-col items-center bg-white lg:p-2 mt-4">
      <div className="text-center " data-aos="fade-down">
        {/* <div className="flex items-center justify-center"> */}
          <h1 className="text-4xl font-semibold font-share-tech flex items-center justify-center lg:p-0 gap-6">
            <span style={{ display: 'inline-block', verticalAlign: 'bottom' }}>
              About Us
            </span>
            {/* <img
              src={images.logo}
              className="mix-blend-screen "
              style={{
                width: '215px',
                display: 'inline-block',
                verticalAlign: 'middle',
              }}
              alt="invontec"
            /> */}
          </h1>
        {/* </div> */}
        <p className=" text-xl text-gray-700" data-aos="fade-up-left">
          <strong className="">20+ years of experience</strong> in transforming
          businesses to do better every day
        </p>
      </div>
      <div className="mt-4 w-full justify-between flex items-center">
        <div className="lg:flex justify-center items-center lg:justify-evenly w-full gap-2 flex flex-wrap sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 ">
          {data.map((item, index) => (
            <AboutCard
              title={item.title}
              key={index}
              color={item.color}
              icon={item.icon}
              subtitle={item.subtitle}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
