import React from "react";
// import { Carousel } from "react-responsive-carousel";

const Phone = (
  // {
    //  images
  // }
) => {
  // const PhoneScreen = ({ image }) => {
  //   return (
  //     <img
  //       className=" flex flex-col justify-between items-center p-2 h-[580px] "
  //       src={image}
  //       alt="phone"
  //     />
  //   );
  // };

  return (
    <div className="relative bg-black rounded-2xl w-[350px] h-[600px] p-2 m-2">
      <div className="h-full w-full rounded-xl flex flex-col text-xs font-bold bg-gray-200 shadow-xl transform rotate-3d">
        {/* <Carousel
          className="w-full bg-white h-full"
          showArrows
          autoPlay
          infiniteLoop
          showThumbs={false}
          showIndicators={false}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
        >
          {images.map((image, index) => (
            <PhoneScreen image={image} key={index}></PhoneScreen>
          ))}
        </Carousel> */}
      </div>
    </div>
  );
};

export default Phone;
