import React from 'react';
import images from '../../../../constants/images';
import TwoButtons from '../../../../UI/Buttons/TwoButtons/TwoButtons';

const Experts = () => {
  return (
    <div className="pt-16 pb-16 mt-10 bg-gray-100">
      <div className="container mx-auto">
        <div className="md:flex md:items-center md:flex-row-reverse">
          <div className="md:w-1/2 md:p-8">
            <h2 className="lg:tracking-normal tracking-wide text-2xl md:text-3xl font-semibold text-primary mb-4">
              
              <strong className="secondary-blue-color lg:tracking-normal tracking-wide"> Salesforce</strong>
            </h2>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-base mb-4">
              Salesforce is the world’s #1 customer relationship management
              (CRM) platform. Salesforce provides cloud-based, CRM applications
              for sales, service, marketing, and more.
            </p>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-base mb-4">
              Salesforce has essentially changed how enterprise software is
              delivered and used. Its software is cloud-based and hence doesn’t
              need IT experts to set up anything. Salesforce has defined the
              ideal way as how to connect with customers. Building meaningful
              and lasting bond with the customers, identifying their needs,
              address problems faster and deploy apps that are customer focused
              is all possible through Salesforce.
            </p>

            <div className="mt-6">
              <TwoButtons />
            </div>
          </div>
          <div className="md:w-10/12">
            <img src={images.sfdc4} alt="UX And Fiori" className="w-full " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experts;
