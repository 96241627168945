import React from 'react';
import images from '../../../../../../constants/images';
import TwoButtons from '../../../../../../UI/Buttons/TwoButtons/TwoButtons';

const OurBiServicesSection = () => {
  return (
    <section id="our-bi-services" className="pt-16 pb-16 mt-10 bg-white">
      <div className="container mx-auto">
        <div className="md:flex md:items-center">
          {/* <div className="md:w-1/2 md:p-12"> */}
            <h2 className="text-2xl md:text-3xl font-semibold text-primary mb-6">
              OUR AMS{' '}
              <strong className="text-secondary-blue-color">COMPETENCY</strong>
            </h2>
          {/* </div> */}
        </div>
        <div className="flex justify-center mt-10">
          <div className="w-full ">
            <div className="module-box">
              <div className="module-img mb-4">
                <img src={images.biservice} alt="AMS Competency" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-6">
          <div className="col-md-12 text-center">
            <TwoButtons />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurBiServicesSection;
