import React from 'react';
import './OurCredentialCard.css'; // Import the custom CSS file

const OurCredentialCard = ({ title, points }) => {
  return (
    <div className="lg:m-10 m-2 bg-slate-600 rounded-lg shadow-xl transform transition duration-300 hover:scale-105 p-6 lg:w-1/2 credential-card hover:cursor-pointer">
      <h4 className="lg:py-4 text-primary font-bold text-4xl tracking-wider">{title}</h4>
      <ul className="leading-7 text-white font-semibold space-y-4">
        {points.map((point, index) => (
          <li key={index} className="flex text-left lg:text-lg text-sm ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2 text-primary"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
            {point}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OurCredentialCard;
