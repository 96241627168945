import React from 'react';

const CandidateList = ({ data }) => {
  return (
    <div className="p-4">
      <h1 className="text-2xl font-semibold mb-4">Candidate List</h1>
      {data.map((item) => (
        <div key={item.jobId} className="mb-6">
          <h2 className="text-lg font-semibold mb-2">
            Job Title: {item?.jobDetails?.title}
          </h2>
          <div className="border p-4 bg-white rounded shadow">
            {item?.candidates?.map((candidate) => (
              <div key={candidate._id} className="mb-4">
                <h3 className="text-md font-semibold mb-1">
                  Candidate Name: {candidate?.users[0]?.name}
                </h3>
                <p className="lg:tracking-normal tracking-wide">Email: {candidate?.users[0]?.email}</p>
                <p className="lg:tracking-normal tracking-wide">Phone: {candidate?.users[0]?.phone}</p>
                <p className="lg:tracking-normal tracking-wide">Message: {candidate?.users[0]?.message}</p>
                <a
                  href={candidate?.users[0]?.resume}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline mt-2 block"
                >
                  Download Resume
                </a>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default CandidateList;
