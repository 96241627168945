import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import api from "../apis/api";

const MessageList = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    try {
      const response = await api.Message.getAllMessages(); // Replace with your API endpoint
      setMessages(response.data);
    } catch (error) {
      if (error?.response?.data) return toast.error(error.response.data.error);
      toast.error(error.message);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-3xl font-bold mb-6">Message List</h2>
      <ul className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {messages.map((message) => (
          <li
            key={message._id}
            className="p-6 border rounded-lg shadow-md bg-white hover:shadow-lg transition duration-300 ease-in-out transform hover:-translate-y-1"
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">{message.name}</h3>
              <p className="text-gray-500 text-sm">
                {new Date(message.createdAt).toLocaleString()}
              </p>
            </div>
            <p className="text-gray-600 mb-2">Email: {message.businessEmail}</p>
            <p className="text-gray-600 mb-2">Phone: {message.phoneNo}</p>
            <p className="text-gray-600 mb-2">Company: {message.company}</p>
            <p className="text-gray-600 mb-2">
              Total Employees: {message.totalEmployee}
            </p>
            <p className="text-gray-600 mb-2">Turnover: {message.turnOver}</p>
            <p className="text-gray-700 mt-2">Message: {message.message}</p>
            <p className="text-gray-700 mt-2">Form Name: {message.formName}</p>
            <p className="text-gray-700 mt-2">Page: {message.pathName}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MessageList;
