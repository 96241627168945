import React from 'react';
import { FcAssistant } from 'react-icons/fc';
import TwoButtons from '../../../UI/Buttons/TwoButtons/TwoButtons';
const BusinessBoost = () => {
  return (
    <div className="flex flex-col items-center  bg-white gap-5 mt-20 ">
      <FcAssistant size={60} />
      <div className="text-center " data-aos="fade-down">
        <h1 className="text-5xl font-semibold font-share-tech p-2 lg:p-0">
          Ready to give your
          <span className=" tracking-wide"> Business a boost?</span>
        </h1>
        <p className="text-lg text-gray-700 text-xl font-bold mt-4 lg:tracking-normal tracking-wide" data-aos="fade-up-left">
          Let our industry trained and SAP certified experts show you proven
          industry-specific solutions designed to integrate seamlessly with SAP.
        </p>
      </div>

      {/* <div className="w-1/2  items-center justify-center hidden lg:flex" data-aos="fade-up-right">
        <img
          src={images.about}
          alt="about"
          className="mix-blend-multiply lg:mt-20 aspect-auto mt-10 p-2 "

        />
      </div> */}
      <div className='mt-10'>
        <TwoButtons />
      </div>
      <div className= 'mt-4'>

      </div>
    </div>
  );
};

export default BusinessBoost;
