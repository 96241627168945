import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { BsCapsule } from "react-icons/bs";
import { GiRadioTower, GiChemicalDrop } from "react-icons/gi";
import { BsFillTelephoneFill, BsBusFrontFill } from "react-icons/bs";
import {
  MdSportsVolleyball,
  MdCastForEducation,
  MdAgriculture,
  MdCurrencyExchange,
} from "react-icons/md";
import { AiOutlineShop } from "react-icons/ai";
import { RiComputerLine } from "react-icons/ri";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./MakeInTransition.css";

import TransitionCard from "../../../../UI/Cards/TransitionCard/TransitionCard";
// import required modules
import {
  EffectCoverflow,
  Pagination,
  Navigation,
  Autoplay,
} from "swiper/modules";

export default function MakeInTransition() {
  // const [isHovered, setIsHovered] = useState(false);
  const data = [
    {
      title: "Pharma",
      description:
        "The software is helping Indian Pharmaceutical firms to improve the speed of decision making, automate complex Batch Manufacturing Records (BMR) and manage the sales process.",
      icon: <BsCapsule color="white" fontSize={60} />,
    },
    {
      title: "Electronics",
      description:
        "The solution helps the electronics industry to track variants items and versions, improve quality control across the supply chain, helps in advance planning, scheduling and auto-batch management. ",
      icon: <GiRadioTower color="white" fontSize={60} />,
    },
    {
      title: "Chemical",
      description:
        "This enterprise-ready solution facilitates companies to perform production planning, track byproducts and coproducts, yield, waste-age, shelf life, expiry date, FIFO and FEFO and manage stock at multiple locations.  ",
      icon: <GiChemicalDrop color="white" fontSize={60} />,
    },
    {
      title: "Telecom",
      description:
        "The solution takes care of complete leasing and renewal promotions, events and signage management.The system also helps in billing management, security management and staff management. ",
      icon: <BsFillTelephoneFill color="white" fontSize={60} />,
    },
    {
      title: "Trading",
      description:
        "This solution integrates seamlessly with various processes of inventory, purchase, sales and distribution. It tracks and manages every step with precision and eliminates manual errors.",
      icon: <MdCurrencyExchange color="white" fontSize={60} />,
    },
    {
      title: "Automotive",
      description:
        "Improve supply chain management with a single and centralized system from production, sales and shipping to purchasing, goods receipt, warehouse management and communication management.  ",
      icon: <BsBusFrontFill color="white" fontSize={60} />,
    },
    {
      title: "System Integrator",
      description:
        " The solution helps to streamline your financial operation, from daily accounting tasks to banking and financial reporting and analysis. It also manages the complete  order-to-pay cycle, including receipts, invoices, and returns. ",
      icon: <RiComputerLine color="white" fontSize={60} />,
    },
    {
      title: "Sports",
      description:
        "Effortlessly manage a complete repository of cricket and other sports items such as football, baseball, rugby, ice hockey and other sports kits through item master in SAP. The software helps you to plan a complex packaging process, and meet the desired quality benchmarks.",
      icon: <MdSportsVolleyball color="white" fontSize={60} />,
    },
    {
      title: "Education",
      description:
        " Reimagine student engagement and learning models through educational ERP.  With this ERP, forget about the hassle of maintaining heaps of paperwork and effortlessly manage time, admission, recruitment, grades and exam operations with SAP. ",
      icon: <MdCastForEducation color="white" fontSize={60} />,
    },
    {
      title: "Retail",
      description:
        " Get actionable dashboard of your stores’ sales, orders, and other data such as how much revenue you are making on each product cycle with SAP. Keep a track of shift scheduling, time clocks and a host of retail operation activities with this ERP.  ",
      icon: <AiOutlineShop color="white" fontSize={60} />,
    },
    {
      title: "Agriculture",
      description:
        "This solution records multiple locations, handles scheduling and inventory of Agriculture product types and categories. It also suggests alternative routes to reduce delivery time. ",
      icon: <MdAgriculture color="white" fontSize={60} />,
    },
  ];
  // return (
  //   <div className="w-full flex items-center justify-center flex-col">
  //     <div className="text-center mt-10 w-full" data-aos="fade-down">
  //       <h1 className="text-5xl font-semibold font-share-tech p-2 lg:p-0">
  //         Making in Transition to{' '}
  //         <span className=" tracking-wide">Enterprise-Ready ERP</span>
  //       </h1>
  //     </div>
  //     <div className="lg:w-5/6 w-full">
  //       <Swiper
  //         effect={'coverflow'}
  //         grabCursor={true}
  //         centeredSlides={true}
  //         slidesPerView={'auto'}
  //         coverflowEffect={{
  //           rotate: 50,
  //           stretch: 0,
  //           depth: 100,
  //           modifier: 1,
  //           slideShadows: true,
  //         }}
  //         pagination={true}
  //         navigation={true} // Add navigation prop
  //         modules={[EffectCoverflow, Pagination, Navigation]} //
  //         className="mySwiper h-full"
  //         autoplay={{ delay: 0 }}
  //       >
  //         {data.map((item, i) => (
  //           <SwiperSlide>
  //             <TransitionCard
  //               title={item.title}
  //               description={item.description}
  //               icon={item.icon}
  //             />
  //           </SwiperSlide>
  //         ))}
  //       </Swiper>
  //     </div>
  //   </div>
  // );

  return (
    <div className="w-full flex items-center justify-center flex-col">
      <div className="text-center mt-10 w-full" data-aos="fade-down">
        <h1 className="text-5xl font-semibold font-share-tech p-2 lg:p-0">
          Making in Transition to{" "}
          <span className="tracking-wide">Enterprise-Ready ERP</span>
        </h1>
      </div>
      <div className="lg:w-5/6 w-full">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          navigation={true} // Add navigation prop
          modules={[EffectCoverflow, Pagination, Navigation, Autoplay]} //
          className="mySwiper h-full"
          autoplay={{
            delay: 2500,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
        >
          {data.map((item, i) => (
            <SwiperSlide
              key={i}
              // onMouseEnter={() => setIsHovered(true)} // Set isHovered to true on mouse enter
              // onMouseLeave={() => setIsHovered(false)}
            >
              <TransitionCard
                title={item.title}
                description={item.description}
                icon={item.icon}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
