import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@material-tailwind/react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Provider } from "react-redux";
import store from "./redux/store";
import { NextUIProvider } from "@nextui-org/react";

const rootElement = document.getElementById("root");
const app = (
  <Provider store={store}>
    <ThemeProvider>
      <NextUIProvider>
        <App />
      </NextUIProvider>
    </ThemeProvider>
  </Provider>
);

const root = ReactDOM.createRoot(rootElement);
root.render(app);
