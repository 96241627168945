import React from 'react';
import { useDispatch } from 'react-redux';
import { openForm, setFields } from '../../../redux/formSlice';
const ContactUs = () => {
  const dispatch = useDispatch();
  const handleOpenForm = (fields, buttonClick) => {
    dispatch(setFields({ fields, buttonClick }));
    dispatch(openForm());
  };
  const letsTalkFields = [
    {
      name: 'Name',
      type: 'text',
      required: true,
      fieldKey: 'name',
    },
    {
      name: 'Business E-mail',
      type: 'text',
      required: true,
      fieldKey: 'businessEmail',
    },
    {
      name: 'Phone number',
      type: 'number',
      required: true,
      fieldKey: 'phoneNo',
    },
    {
      name: 'Your company',
      type: 'text',
      required: true,
      fieldKey: 'company',
    },
    {
      name: 'Message',
      type: 'textarea',
      required: true,
      fieldKey: 'message',
    },
  ];

  return (
    <button
      className="font-bold  bg-purple-500 text-white px-4 py-2 rounded-xl mt-1"
      onClick={() => {
        handleOpenForm(letsTalkFields, 'lets talk');
      }}
    >
      Contact Us
    </button>
  );
};

export default ContactUs;
