import React from 'react';
import TwoButtons from '../../../../../../UI/Buttons/TwoButtons/TwoButtons';
import ContactNow from '../../../../../../UI/Buttons/ContactNow/ContactNow';
import images from '../../../../../../constants/images'

const ServicesSection = () => {
  return (
    <section id="sap-modules" className="pt-10 pb-20 bg-gray-100 mt-10">
      <div className="container mx-auto">
        <div className="text-center">
          <h2 className="lg:tracking-normal tracking-wide text-2xl md:text-3xl font-semibold text-primary mb-4">
            <strong>SERVICES</strong>
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-8">
          {/* Service Card 1 */}
          <div className="bg-white shadow-md p-6 rounded-md">
            <div className="module-img mb-4">
              <img
                src={images.blueIcon1}
                alt=""
              />
            </div>
            <span className="module-title font-semibold text-primary">
              UX Enablement and Onboarding
            </span>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-sm mt-2">
              Complete your UX transformation journey with our personalized
              Fiori enablement services, focused on feedback-based optimization.
            </p>
            <ul className="default mt-4 font-semibold">
              <li>Fiori landscape preparation</li>
              <li>Installation and Fiori configuration</li>
              {/* Add other list items here */}
            </ul>
            {/* <ContactNow /> */}
          </div>

          {/* Service Card 2 */}
          <div className="bg-white shadow-md p-6 rounded-md">
            <div className="module-img mb-4">
              <img
                src={images.blueIcon2}
                alt=""
              />
            </div>
            <span className="module-title font-semibold text-primary">
              UX Assessment
            </span>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-sm mt-2">
              Get the most out of your Fiori-based UX transformation. We work
              with you to define the best platform, architecture, and framework
              for your journey.
            </p>
            <ul className="default mt-4 font-semibold">
              <li>Optimized landscape for Fiori-based UX</li>
              <li>Define UX roadmap with best practices</li>
              {/* Add other list items here */}
            </ul>
           
          </div>

          {/* Service Card 3 */}
          <div className="bg-white shadow-md p-6 rounded-md">
            <div className="module-img mb-4">
              <img
                src={images.blueIcon3}
                alt=""
              />
            </div>
            <span className="module-title font-semibold text-primary">
              UX Design
            </span>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-sm mt-2">
              Interested in a custom UX solution based on SAP Fiori principles?
            </p>
            <p className="text-gray-600 text-sm">
              <strong>The key elements of offerings are:</strong>
            </p>
            <ul className="default mt-2 font-semibold">
              <li>Human-centered and outcome-based design</li>
              <li>Iterative and collaborative</li>
              {/* Add other list items here */}
            </ul>
            {/* <ContactNow /> */}
          </div>
        </div>
        <div className='flex justify-center inner center'>
            <ContactNow />
            </div>  
        <div className="text-center mt-10">
          <TwoButtons />
        </div>
        
      </div>
    </section>
  );
};

export default ServicesSection;
