import React, { useState } from "react";
import api from "../apis/api";
import { toast } from "react-toastify";
import Loader from "../UI/Loader";
const AddUser = ({ setShowUserModal, fetchUsers }) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    role: "other",
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (formData.password !== formData.confirmPassword) {
        return toast.error("Password not matched");
      }
      if (formData.password.length < 6) {
        return toast.error("Password must be greater than 6 digit");
      }
      setIsLoading(true);
      await api.Auth.signup(formData);
      toast.success("User added successfully");
      setShowUserModal(false);
      fetchUsers();
    } catch (error) {
      if (error?.response?.data) return toast.error(error.response.data.error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="">
      <h3 className="mb-4 text-xl font-medium ">Add User </h3>
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div className="flex justify-between  lg:flex-row flex-col">
          <label
            htmlFor="title"
            className="block mb-2 text-sm font-bold text-black"
          >
            Username
          </label>
          <input
            type="text"
            name="username"
            id="username"
            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-1"
            placeholder="Username"
            required
            value={formData.username}
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-between  lg:flex-row flex-col">
          <label
            htmlFor="password"
            className="block mb-2 text-sm font-bold text-black"
          >
            Password
          </label>
          <input
            type="password"
            name="password"
            id="password"
            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-1"
            required
            value={formData.password}
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-between  lg:flex-row flex-col">
          <label
            htmlFor="password"
            className="block mb-2 text-sm font-bold text-black"
          >
            Confirm Password
          </label>
          <input
            type="password"
            name="confirmPassword"
            id="confirmPassword"
            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-1"
            required
            value={formData.confirmPassword}
            onChange={handleChange}
          />
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className=" bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white"
        >
          {isLoading ? <Loader /> : "Add"}
        </button>
      </form>
    </div>
  );
};

export default AddUser;
