import { createSlice } from '@reduxjs/toolkit';
const loadStateFromLocalStorage = () => {
  try {
    const serializedState = localStorage.getItem('userState');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

const saveStateToLocalStorage = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('userState', serializedState);
  } catch (error) {
    // Handle error if needed
  }
};
const userSlice = createSlice({
  name: 'user',
  initialState: loadStateFromLocalStorage() || {
    isAuthenticated: false,
    id: null,
    username: null,
    token: null,
    loading: false,
    role: null,
    error: null,
  },
  reducers: {
    login: (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.id = action.payload.id;
      state.username = action.payload.username;
      state.token = action.payload.token;
      state.role = action.payload.role;
      saveStateToLocalStorage(state);
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.id = null;
      state.username = null;
      state.token = null;
      state.role = null;
      state.loading = false;
      state.error = null;
      saveStateToLocalStorage(state);
    },
  },
  // ... rest of the code ...
});

export const { login, logout } = userSlice.actions;

export default userSlice.reducer;
