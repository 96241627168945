import React from "react";
import BusinessBoost from "../../Services/components/BusinessBoost";
import ApplicationManagement from "./components/ApplicationManagement";
import ErpEmpowerSection from "../ERP_Solution/Blogs/SapAmsService/components/ErpEmpowerSection";
import Header from "../../../common/components/Header";
import { Helmet } from "react-helmet";

const AmsManagement = () => {
  return (
    <>
      <Helmet>
        <title>Invontec | AMS Management</title>
        <link rel="canonical" href="https://www.invontec.com/ams-management" />
      </Helmet>
      <div>
        <Header title="AMS  Management" />
        <div className="mt-10 w-full">
          <ApplicationManagement />
          <ErpEmpowerSection />
          <BusinessBoost />
        </div>
      </div>
    </>
  );
};

export default AmsManagement;
