import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import OurCredentialCard from '../../../../UI/Cards/OurCredentialCard/OurCredentialCard';

export default function OurCredential() {
  const data = [
    {
      title: 'World’s Largest Fresh Fruits Manufacturer',
      points: [
        'SAP S4 Hana Implementation',
        'Training Mobile APP introduction and implementation',
        'SOD Automated tool development and implementation',
        'Support through ABAP Factory',
        'BI Dashboard and Analytics',
        'Penetration test and performance test',
      ],
    },
    {
      title: 'World’s Largest Oil and Lubricants',
      points: [
        'BI Dashboard and Analytics',
        'Azure Data Lake and cloud BI platform implementation',
        'Support through ABAP Factory',
        'AMS Management',
        'Management Consulting',
        'S4 Hana Assessment',
      ],
    },
    {
      title: 'IS, Retail, and Beverages',
      points: [
        'BI Dashboard and Analytics',
        'POS system implementation',
        'Hybris and CRM implementation',
        'AMS Management',
        'Management Consulting',
        'Success factor implementation',
      ],
    },
    {
      title: 'World’s Largest Sales and Manufacturing Company',
      points: [
        'Management consulting services',
        'Hana Assessment / POC',
        'SOD project implement',
        'Services Management with SFDC integration',
        'AMS Support',
        'Support through ABAP Factory',
      ],
    },
    // Add more objects if needed...
  ];

  return (
    <div className="w-full flex items-center justify-center flex-col">
      <div className="text-center w-full" data-aos="fade-down">
        <h1 className="text-5xl font-semibold font-share-tech p-2 lg:p-0">
          Our <span className="tracking-wide">Credentials</span>
        </h1>
        <p
          className="mt-4 font-bold text-gray-700 text-xl lg:tracking-normal tracking-wide"
          data-aos="fade-up-left"
        >
          Innovative products are made to meet the demands of startups,
          industrial & enterprise giants.
        </p>
      </div>
      <Carousel
        autoPlay={true}
        infiniteLoop={true}
        showArrows={true}
        showThumbs={false}
        showStatus={false}
        interval={2500}
        emulateTouch={true}
        stopOnHover={true}
        className='flex w-full items-center justify-center '
      >
        {data.map((item, index) => (
          <div key={index} className='flex w-full items-center justify-center '>
            <OurCredentialCard title={item.title} points={item.points} />
          </div>
        ))}
      </Carousel>
    </div>
  );
}
