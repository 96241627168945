import React from 'react';
import { useDispatch } from 'react-redux';
import { openForm, setFields } from '../../../redux/formSlice';
const TwoButtons = () => {
  const dispatch = useDispatch();
  const handleOpenForm = () => {
    dispatch(openForm());
  };
  const requestDemoFields = [
    {
      name: 'Name',
      type: 'text',
      required: true,
      fieldKey: 'name',
    },
    {
      name: 'Business E-mail',
      type: 'text',
      required: true,
      fieldKey: 'businessEmail',
    },
    {
      name: 'Phone number',
      type: 'number',
      required: true,
      fieldKey: 'phoneNo',
    },
    {
      name: 'Your company',
      type: 'text',
      required: true,
      fieldKey: 'company',
    },
    {
      name: 'Total Employees',
      type: 'text',
      required: true,
      fieldKey: 'totalEmployee',
    },
    {
      name: 'Turn Over',
      type: 'text',
      required: true,
      fieldKey: 'turnOver',
    },
    {
      name: 'Message',
      type: 'textarea',
      required: true,
      fieldKey: 'message',
    },
  ];
  const letsTalkFields = [
    {
      name: 'Name',
      type: 'text',
      required: true,
      fieldKey: 'name',
    },
    {
      name: 'Business E-mail',
      type: 'text',
      required: true,
      fieldKey: 'businessEmail',
    },
    {
      name: 'Phone number',
      type: 'number',
      required: true,
      fieldKey: 'phoneNo',
    },
    {
      name: 'Your company',
      type: 'text',
      required: true,
      fieldKey: 'company',
    },
    {
      name: 'Message',
      type: 'textarea',
      required: true,
      fieldKey: 'message',
    },
  ];

  const handleButtonClick = (fields, buttonClick) => {
    dispatch(setFields({ fields, buttonClick }));
  };
  return (
    <div className="flex flex-wrap gap-10 mb-4">
      <span
        className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded-full transition font-semibold duration-300 ease-in-out cursor-pointer"
        onClick={() => {
          handleButtonClick(requestDemoFields, 'request demo');
          handleOpenForm();
        }}
      >
        REQUEST DEMO
      </span>

      <span
        className="border border-blue-500 text-blue-500 py-2 px-4 rounded-full hover:bg-blue-500 hover:text-white transition duration-300 ease-in-out cursor-pointer font-semibold"
        onClick={() => {
          handleButtonClick(letsTalkFields, 'lets talk');
          handleOpenForm();
        }}
      >
        LET'S TALK
      </span>
    </div>
  );
};

export default TwoButtons;
