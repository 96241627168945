import React from 'react';
import TwoButtons from '../../../../../../UI/Buttons/TwoButtons/TwoButtons';
import images from '../../../../../../constants/images';

const TopServerSection = () => {
  const isMobileView = () => window.innerWidth <= 768;
  return (
    <section
      id="top-server-section"
      className="py-20 bg-gray-100 bg-contain bg-right bg-no-repeat"
      style={{
        backgroundImage: isMobileView() ? 'none' : `url(${images.server})`,
      }}
    >
      <div className="container mx-auto">
        <div className="md:flex md:items-center">
          <div className="md:w-1/2 md:p-8 md:pr-12">
            <h2 className="text-xl md:text-3xl font-semibold text-primary mb-2 md:mb-4 lg:tracking-normal tracking-wide">
              Ensure Your Business <br />
              <strong className="secondary-blue-color lg:tracking-normal tracking-wide">
                is Always Up &amp; Running
              </strong>
            </h2>
            <p className="text-gray-600 text-sm md:text-base mb-4 lg:tracking-normal tracking-wide">
              To stay competitive in today’s dynamic business environment,
              companies require uninterrupted operation of services for their
              enterprise applications. Clients across industries partner with
              Invontec to leverage our robust support and maintenance services
              for delivering long-term stability, maximized ROI, and minimized
              total cost of ownership and maintenance.
            </p>
            <p className="text-gray-600 text-sm md:text-base mb-4 lg:tracking-normal tracking-wide">
              With our deep SAP experience and proven methodologies, we offer
              our customers seamless and cost-effective SAP Operations support.
            </p>
            <div className="mt-4 md:mt-6">
              <TwoButtons />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopServerSection;
