import React from 'react';
import images from '../../../constants/images';
const AboutUs = () => {

  return (
    <div className="flex flex-col items-center  bg-white lg:p-10">
      <div className="text-center flex w-full justify-center items-center flex-col" data-aos="fade-down">
        <h1 className="text-5xl font-semibold font-share-tech p-2 lg:p-0">
          Quest for <span className=" tracking-wide">Excellence</span>
        </h1>
        <p className="mt-4 text-lg font-bold text-gray-700 w-4/5 lg:tracking-normal tracking-wide" data-aos="fade-up-left">
        Our Quest for excellence led to the creation of an IT conglomerate that spans multiple geographies, industries and business verticals. invontec today is a fast-growing IT company with a headcount of 150+ employees, offices across India and a global footprint.
        </p>
      </div>
    <div className='lg:mt-4 lg:p-8 mt-2'>
        <img src={images.quest} alt="quest" />
    </div>
    </div>
  );
};

export default AboutUs;
