import React from 'react';
import styles from './SolutionCard.module.css';

const SolutionCard = ({ title, description, image }) => {
  return (
    <div className={styles.cardContainer}>
      <div className={styles.card}>
        <div className={styles.frontContent+' flex flex-col'}>
          <img src={image} alt={title} />
          <p className='lg:tracking-normal tracking-wide'>{title}</p>
        </div>
        <div className={styles.content}>
          <p className={styles.heading+' lg:tracking-normal tracking-wide'}>{title}</p>
          <p className='lg:tracking-normal tracking-wide'>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default SolutionCard;
