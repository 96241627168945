import React from 'react';
import images from '../../../../constants/images';
import SolutionCard from '../../../../UI/Cards/SolutionCard/SolutionCard';
import TwoButtons from '../../../../UI/Buttons/TwoButtons/TwoButtons';

const Solutions = () => {
  const data = [
    {
      title: 'Implementation',
      description:
        'Over 50 successful implementations have leveraged the SAP-certified Accelerated SAP (ASAP) methodology, seamlessly integrated with Customer Relationship Management (CRM) solutions, to drive efficient business transformations across diverse industries.',
      image: images.icon1,
    },
    {
      title: 'Customization',
      description:
        'Customization involves skillfully tailoring the SAP Business One ERP software, coupled with Customer Relationship Management (CRM), to precisely align with individual customer requirements. This dynamic approach ensures enhanced customer engagement and satisfaction.',
      image: images.icon2,
    },
    {
      title: 'Support',
      description:
        'Consistent training, upgradation and dedicated support to ensure a smooth transition.',
      image: images.icon3,
    },
    {
      title: 'Managed Services',
      description:
        'End-to-end services for Disaster recovery, Server management and hosting',
      image: images.icon4,
    },
    {
      title: 'Migration',
      description: 'Effortlessly transitioning, including CRM integration, to SAP HANA or any advanced SAP versions, ensuring a streamlined migration that empowers businesses with enhanced capabilities while maintaining optimal customer relationship management.',
      image: images.icon5,
    },
    {
      title: 'Business Process Mapping',
      description: 'Detailed requirement gathering and gap analysis.',
      image: images.icon6,
    },
  ];

  return (
    <div className="flex flex-col items-center  bg-white lg:p-10 ">
      <div className="text-center " data-aos="fade-down">
        <h1 className="text-5xl font-semibold font-share-tech p-2 lg:p-0 ">
          We Provide Solution{' '}
          <span className=" tracking-wide">For every need</span>
        </h1>
        <p className="mt-4 font-bold text-gray-700 text-xl lg:tracking-normal tracking-wide" data-aos="fade-up-left">
          From implementation to support, we work closely with you to ensure
          seamless, successful projects, every time!
        </p>
      </div>
      <div className="flex w-full p-10">
        <div className="  flex flex-wrap  justify-center items-center lg:justify-evenly p-10  w-full  ">
          <div className="flex flex-wrap gap-10 lg:grid lg:grid-cols-3 justify-center items-center">
            {data.map((item, i) => (
              <SolutionCard
                key={item.title}
                title={item.title}
                description={item.description}
                index={i}
                image={item.image}
              />
            ))}
          </div>
        </div>
      </div>
     <TwoButtons />
    </div>
  );
};

export default Solutions;
