import React, { useEffect, useState } from "react";
import JobDetails from "./components/JobDetails";
import api from "../../../apis/api";
import { useParams } from "react-router-dom";
import ApplyToJob from "./components/ApplyToJob";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
const Job = () => {
  const [job, setJob] = useState({});
  const { id } = useParams();
  const fetchJob = async () => {
    try {
      const res = await api.Job.getJobById(id);
      setJob(res.data);
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };
  useEffect(() => {
    fetchJob();
  }, []);

  return (
    <>
      <Helmet>
        <title>Invontec | Job</title>
        <link rel="canonical" href="https://www.invontec.com/contact-us" />
      </Helmet>
      <div className="flex justify-center items-center bg-gray-100 min-h-screen">
        <div className="w-full py-20">
          <div className="flex justify-center  w-full lg:flex-row flex-col">
            <JobDetails job={job} />
            <ApplyToJob jobId={id} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Job;
