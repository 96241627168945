import React from 'react';
import images from '../../../../constants/images';
import TalkToExpert from '../../../../UI/Buttons/TalkToExpert/TalkToExpert';

const ApplicationManagement = () => {
  return (
    <div className="pt-16 pb-16 bg-gray-100">
      <div className="container mx-auto">
        <div className="md:flex md:items-center">
          <div className="md:w-1/2 md:p-8">
            <h2 className="lg:tracking-normal tracking-wide text-2xl md:text-3xl font-semibold text-primary mb-4">
              Application Management Services
              <strong className="secondary-blue-color lg:tracking-normal tracking-wide">
               {'  '} Monitoring, Troubleshooting, Enhancement, Optimization
              </strong>
            </h2>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-base mb-4">
              To stay competitive in today’s dynamic business environment,
              companies require uninterrupted operation of services for their
              enterprise applications. Clients across industries partner with
              Invontec to leverage our robust support and maintenance services
              for delivering long-term stability, maximized ROI and minimized
              total cost of ownership and maintenance.
            </p>

            <div className="mt-6">
              <TalkToExpert />
            </div>
          </div>
          <div className="md:w-10/12">
            <img src={images.amsOverview} alt="UX And Fiori" className="w-full mix-blend-multiply" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationManagement;
