import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice'; // Import your userSlice
import formReducer from './formSlice';
const store = configureStore({
  reducer: {
    user: userReducer, // Add your userSlice reducer here
    // You can add other reducers here if needed
    modal: formReducer,
  },
});

export default store;
