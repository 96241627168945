import { Helmet } from "react-helmet";
import React from "react";
import images from "../../constants/images";
import TalkToExpert from "../../UI/Buttons/TalkToExpert/TalkToExpert";
import MakeInTransition from "../Home/components/MakeInTransition/MakeInTransition";
import Solutions from "../Home/components/Solutions/Solutions";
import QuestForExcellence from "./components/QuestForExcelence";
const ImageFirst = () => {
  return (
    <div className="overflow-hidden">
      <div className="flex justify-center items-center lg:h-full "></div>
      <div className="flex flex-col lg:h-full w-full mt-4 lg:flex-row">
        <img
          src={images.aboutHeader}
          alt="theater"
          className="lg:ml-40  lg:p-2 rounded-lg  lg:w-1/4 "
          // data-aos="flip-up"
          // data-aos-duration="1500"
        />

        <div
          className="flex flex-col lg:flex-col w-full "
          data-aos="flip-down"
          data-aos-duration="1500"
        >
          <p className="text-xl p-5 w-full lg:tracking-normal tracking-wide">
            Invontec is an IT services company specialized in ERP, CRM and
            Process Automation Engineering. We bring 20+ years of successful IT
            services expertise to help our customer in meeting their dynamic IT
            needs.
          </p>
          <p className="text-xl p-5 lg:tracking-normal tracking-wide">
            Invontec delivers high caliber information technology solutions to
            our customers. We do not merely apply our available resources
            rather, we define and deliver the resources needed for each project.
          </p>
          <p className="text-xl p-5 py-2 lg:tracking-normal tracking-wide">
            Our Application Development process leverages subject matter
            expertise to ensure projects are customized to meet needs and
            delivered quickly. Invontec takes on the complex challenges of our
            customers and works as a trusted partner to develop innovative
            solutions in support of their missions.
          </p>
          <div className="flex justify-center items-center mr-40 py-2">
            <TalkToExpert />
          </div>
        </div>
      </div>
    </div>
  );
};

const About = () => {
  // const scrollRef = useRef(null);
  // useScrollSnap({ ref: scrollRef, duration: 100, delay: 50 });
  return (
    <>
      <Helmet>
        <title>Invontec | About Us</title>
        <link rel="canonical" href="https://www.invontec.com/about" />
      </Helmet>
      <div className="overflow-hidden">
        <div className="  flex justify-center items-center  flex-col gap-5 overflow-hidden">
          <div className="w-full">
            <img
              src={images.aboutBg}
              alt="about"
              className="w-full h-auto"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="500"
              style={{ width: "100%", height: "200px" }}
            />
          </div>

          <div className="flex flex-col justify-center items-center  ">
            <div className="text-center mt-2 lg:p-0" data-aos="fade-down">
              <h1 className="lg:text-5xl text-4xl font-semibold font-share-tech flex items-center justify-center lg:p-0 gap-6">
                <span
                  style={{ display: "inline-block", verticalAlign: "bottom" }}
                >
                  About
                </span>
                <img
                  src={images.logo}
                  className="mix-blend-screen lg:w-[215px] w-[160px]"
                  style={{
                    display: "inline-block",
                    verticalAlign: "middle",
                  }}
                  alt="invontec"
                />
              </h1>
            </div>
            <ImageFirst />
          </div>
          {/* <Umbrella /> */}
          <MakeInTransition />
          <Solutions />
          <QuestForExcellence />
          {/* <InvontecGroupSection /> */}
          {/* <ErpAddOns /> */}
        </div>
      </div>
    </>
  );
};

export default About;
