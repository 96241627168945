import React, { useEffect, useState } from 'react';
import api from '../../../apis/api';
import { Link } from 'react-router-dom';
const CurrentOpenings = () => {
  const [jobOpenings, setJobOpenings] = useState([]);
  const fetchJobOpenings = async () => {
    const res = await api.Job.getAllJobs();
    setJobOpenings(res.data);
  };
  useEffect(() => {
    fetchJobOpenings();
  }, []);

  return (
    <div className="bg-white rounded-lg lg:w-4/5 w-[90%] shadow-lg lg:p-6 p-1" >
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="py-3 px-4 text-left">Job Title</th>
              <th className="py-3 px-4 text-left">Location</th>
              <th className="py-3 px-4 text-left">Experience</th>
              <th className="py-3 px-4 text-left">Min Qualifications</th>
              <th className="py-3 px-4 text-center">Details</th>
            </tr>
          </thead>
          <tbody>
            {jobOpenings.map(
              (job, index) =>
                job.isActive && (
                  <tr key={index} className="border-b border-gray-200">
                    <td className="py-4 px-4 text-gray-800 font-semibold">
                      {job.title}
                    </td>
                    <td className="py-4 px-4">{job.location}</td>
                    <td className="py-4 px-4">{job.experience}</td>
                    <td className="py-4 px-4">{job.minQualification}</td>
                    <td className="py-4 px-4 text-center">
                      <Link
                        className="text-blue-500 hover:underline cursor-pointer"
                        to={`/job/${job._id}`}
                      >
                        View Details
                      </Link>
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CurrentOpenings;
