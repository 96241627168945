import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { NavLink } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi';
import { RxCross1 } from 'react-icons/rx'
import images from '../../constants/images';
export default function TemporaryDrawer({ links }) {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === 'left' || anchor === 'bottom' ? 300 : 250,
        // position: 'relative',
        display: 'flex',
        justifyContent: 'center'
      }}

      onClick={toggleDrawer(anchor, false)}
    >
      <div className="flex flex-col justify-center w-full">
        <div className="flex w-full justify-evenly items-center">
          <img
            src={images.logo}
            alt="invontec Logo"
            className="aspect-auto hover:cursor-pointer h-16 p-4 mr-8"
          />
          <RxCross1 className="text-3xl font-bold" />
        </div>
        <div className="flex flex-col ">
          {links.map((item, i) => (
            <NavLink
              key={i}
              to={item.path}
              className={({ isActive }) => {
                if (isActive) return "hover:cursor-pointer text-md text-primary pl-8 p-2  lg:w-full "
                return "text-black text-md pl-8 p-2 hover:cursor-pointer lg:w-full "
              }}
            >
              {item.title}
            </NavLink>
          ))}
        </div>
      </div>
    </Box>
  );

  return (
    <div className="w-full">
      <GiHamburgerMenu
        color="primary"
        className="text-3xl"
        onClick={toggleDrawer('left', true)}
      />
      <Drawer
        anchor={'left'}
        open={state['left']}
        ModalProps={{
          keepMounted: true,
        }}
      >
        {list('left')}
      </Drawer>
    </div>
  );
}
