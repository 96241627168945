import React from "react";
import BusinessBoost from "../../Services/components/BusinessBoost";
import SFDCSection from "./components/SFDCSection";
import Experts from "./components/Experts";
import Benefits from "./components/Benefits";
import ServicesSection from "../../Blogs/ERP_Solution/Blogs/SfdcAndSap/components/ServicesSection";
import Header from "../../../common/components/Header";
import { Helmet } from "react-helmet";
const SFDC = () => {
  return (
    <>
      <Helmet>
        <title>Invontec | SFDC</title>
        <link rel="canonical" href="https://www.invontec.com/sfdc" />
      </Helmet>
      <div>
        <Header title="Customer Relationship Manager" />
        <div className="mt-10 w-full">
          <SFDCSection />
          <Experts />
          <Benefits />
          <ServicesSection />
          <BusinessBoost />
        </div>
      </div>
    </>
  );
};

export default SFDC;
