import React, { useState } from "react";
import api from "../apis/api";
import { toast } from "react-toastify";

const CreateJob = ({ setShowJobModal, fetchJobs }) => {
  const [formData, setFormData] = useState({
    title: "",
    lastDateToApply: "",
    type: "",
    minQualification: "",
    experience: "",
    location: "",
    salary: "",
    isActive: true,
    description: "",
    additionalInformation: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      await api.Job.createJob(formData);
      toast.success("Job created successfully");
      setShowJobModal(false);
      fetchJobs();
    } catch (error) {
      if (error?.response?.data) return toast.error(error.response.data.error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="">
      <h3 className="mb-4 text-xl font-medium ">Create Job</h3>
      <form className="space-y-6" onSubmit={handleSubmit}>
        <div className="flex justify-between  lg:flex-row flex-col">
          <label
            htmlFor="title"
            className="block mb-2 text-sm font-bold text-black"
          >
            Job Title
          </label>
          <input
            type="text"
            name="title"
            id="title"
            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-1"
            placeholder="Job Title"
            required
            value={formData.title}
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-between  lg:flex-row flex-col">
          <label
            htmlFor="lastDateToApply"
            className="block mb-2 text-sm font-bold text-black"
          >
            Last Date To Apply
          </label>
          <input
            type="date"
            name="lastDateToApply"
            id="lastDateToApply"
            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-1"
            required
            value={formData.lastDateToApply}
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-between  lg:flex-row flex-col">
          <label
            htmlFor="type"
            className="block mb-2 text-sm font-bold text-black"
          >
            Job Type
          </label>
          <input
            type="text"
            name="type"
            id="type"
            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-1"
            placeholder="Job Type"
            required
            value={formData.type}
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-between  lg:flex-row flex-col">
          <label
            htmlFor="minQualification"
            className="block mb-2 text-sm font-bold text-black"
          >
            Minimum Qualification
          </label>
          <input
            type="text"
            name="minQualification"
            id="minQualification"
            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-1"
            placeholder="Minimum Qualification"
            required
            value={formData.minQualification}
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-between  lg:flex-row flex-col">
          <label
            htmlFor="experience"
            className="block mb-2 text-sm font-bold text-black"
          >
            Experience Required
          </label>
          <input
            type="text"
            name="experience"
            id="experience"
            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-1"
            placeholder="Experience Required"
            required
            value={formData.experience}
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-between  lg:flex-row flex-col">
          <label
            htmlFor="location"
            className="block mb-2 text-sm font-bold text-black"
          >
            Job Location
          </label>
          <input
            type="text"
            name="location"
            id="location"
            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-1"
            placeholder="Job Location"
            required
            value={formData.location}
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-between  lg:flex-row flex-col">
          <label
            htmlFor="description"
            className="block mb-2 text-sm font-bold text-black"
          >
            description
          </label>
          <textarea
            type="text"
            name="description"
            id="description"
            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-1"
            placeholder="Write description"
            required
            value={formData.description}
            onChange={handleChange}
          />
        </div>
        <div className="flex justify-between  lg:flex-row flex-col">
          <label
            htmlFor="additionalInformation"
            className="block mb-2 text-sm font-bold text-black"
          >
            Additional Information
          </label>
          <textarea
            type="text"
            name="additionalInformation"
            id="additionalInformation"
            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-1"
            placeholder="Some additional information"
            value={formData.additionalInformation}
            onChange={handleChange}
          />
        </div>
        {/* <div className="flex justify-between  lg:flex-row flex-col">
          <label
            htmlFor="salary"
            className="block mb-2 text-sm font-bold text-black"
          >
            Salary
          </label>
          <input
            type="text"
            name="salary"
            id="salary"
            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-1"
            placeholder="Salary"
            required
            value={formData.salary}
            onChange={handleChange}
          />
        </div> */}
        <div className="flex justify-between  lg:flex-row flex-col">
          <label
            htmlFor="isActive"
            className="block mb-2 text-sm font-bold text-black"
          >
            Is Active?
          </label>
          <select
            name="isActive"
            id="isActive"
            className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-1/2 p-1"
            value={formData.isActive}
            onChange={handleChange}
          >
            <option value={true}>Yes</option>
            <option value={false}>No</option>
          </select>
        </div>
        <button
          disabled={isLoading}
          type="submit"
          className=" bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center text-white"
        >
          Create
        </button>
      </form>
    </div>
  );
};

export default CreateJob;
