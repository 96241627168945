import React from 'react';
import { FaPlayCircle } from 'react-icons/fa';
import TalkToExpert from '../../../../../../UI/Buttons/TalkToExpert/TalkToExpert';
import { Link } from 'react-router-dom';
import images from '../../../../../../constants/images'

const TrainingManagementSection = () => {
  return (
    <section id="tableau-top" className="bg-blue-200 mt-10 py-20">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
        <div className="md:w-1/2 lg:px-6 text-center md:text-left">
          <h2 className="text-2xl md:text-3xl font-semibold text-white mb-2 lg:tracking-normal tracking-wide">
            Training Management <br />
            <strong>using eTAPP</strong>
          </h2>
          <p className="text-gray-700 text-base mb-4 lg:tracking-normal tracking-wide">
            Manage Mid scale to Large scale training program using mobile App
          </p>
          <div className="mb-4">
           <TalkToExpert />
          </div>
          <div>
            <Link
              to="/app-for-training"
              className="btn bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-6 rounded-full transition duration-300 font-bold mb-2"
            >
              READ MORE
            </Link>
          </div>
        </div>
        <div className="md:w-1/2 px-6 text-center">
          <div id="laptop-video">
            <div className="vsc-controller"></div>
            <video width="100%" controls autoPlay muted>
              <source
                src={images.video}
                type="video/mp4"
              />
              Your browser does not support HTML5 video.
            </video>
          </div>
        </div>
      </div>
      <div className="container mx-auto mt-6">
        <ul className="list-disc font-bold list-inside text-gray-700 text-base">
          <li className="flex items-center">
            <FaPlayCircle className="mr-2 text-blue-500" /> Effortless access to
            meeting schedule from your personal Device - Using SAP ID
          </li>
          <li className="flex items-center">
            <FaPlayCircle className="mr-2 text-blue-500" /> Single tap access to
            join the training session via provided meeting application.
          </li>
          <li className="flex items-center">
            <FaPlayCircle className="mr-2 text-blue-500" /> Fiori UX to provide
            same user interface as standard application.
          </li>
          <li className="flex items-center">
            <FaPlayCircle className="mr-2 text-blue-500" /> Device Independent
          </li>
        </ul>
      </div>
    </section>
  );
};

export default TrainingManagementSection;
