import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import api from '../../../apis/api';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Input, InputLabel } from '@mui/material';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function BusinessForms() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const addresses = [
    {
      SalesInquiry: {
        description:
          'Reach out to our Sales team directly for immediate assistance for all sales-related inquiries.',
        formFields: [
          {
            name: 'Name',
            type: 'text',
            required: true,
            fieldKey: 'name',
          },
          {
            name: 'Business E-mail',
            type: 'text',
            required: true,
            fieldKey: 'businessEmail',
          },
          {
            name: 'Phone number',
            type: 'number',
            required: true,
            fieldKey: 'phoneNo',
          },
          {
            name: 'Your company',
            type: 'text',
            required: true,
            fieldKey: 'company',
          },
          {
            name: 'Message',
            type: 'textarea',
            required: true,
            fieldKey: 'message',
          },
        ],
      },
    },
    {
      RequestADemo: {
        description:
          'Request a demonstration of invontec services and products.',
        formFields: [
          {
            name: 'Name',
            type: 'text',
            required: true,
            fieldKey: 'name',
          },
          {
            name: 'Business E-mail',
            type: 'text',
            required: true,
            fieldKey: 'businessEmail',
          },
          {
            name: 'Phone number',
            type: 'number',
            required: true,
            fieldKey: 'phoneNo',
          },
          {
            name: 'Your company',
            type: 'text',
            required: true,
            fieldKey: 'company',
          },
          {
            name: 'Total Employees',
            type: 'text',
            required: true,
            fieldKey: 'totalEmployee',
          },
          {
            name: 'Turn Over',
            type: 'text',
            required: true,
            fieldKey: 'turnOver',
          },
          {
            name: 'Message',
            type: 'textarea',
            required: true,
            fieldKey: 'message',
          },
        ],
      },
    },
    {
      CustomerSupport: {
        description:
          'Get in touch with customer support for quick assistance for any concerns you may have.',
        noFormDetail: {
          tagline: 'Get Unparalleled support from our SAP certified engineers.',
          button: 'Mail Us',
        },
      },
    },
  ];
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [fieldValues, setFieldValues] = React.useState({}); // Define fieldValues state

  const handleFieldChange = (fieldName, value) => {
    setFieldValues((prevFieldValues) => ({
      ...prevFieldValues,
      [fieldName]: value,
    }));
  };
  const handleSubmit = async (event, tabIndex) => {
    try {
      event.preventDefault();

      // Logic for handling form submission based on the tabIndex
      switch (tabIndex) {
        case 0:
          await api.Message.createMessage(fieldValues);
          break;
        case 1:
          await api.Message.createMessage(fieldValues);
          break;
        // No form submission needed for Customer Support
        default:
          break;
      }

      // Clear the form fields after submission
      toast.success('Message sent successsfully!');
      setValue(tabIndex);
      setFieldValues({}); // Clear fieldValues
    } catch (error) {
      if (error?.response?.data) return toast.error(error.response.data.error);
      toast.error(error.message);
    }
  };
  return (
    <div className="bg-white p-10 pt-2 flex justify-center items-center w-full">
      <Box className="bg-white rounded-lg shadow-lg lg:w-4/5">
        <AppBar position="static" variant='outlined'>
          <Tabs

            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"

          >
            <Tab label="SALES ENQUIRY" {...a11yProps(0)} />
            <Tab label="REQUEST A DEMO" {...a11yProps(1)} />
            <Tab label="CUSTOMER SUPPORT" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={value}
          //   onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={value} index={0} dir={theme.direction}>
            <h2 className="text-xl font-semibold mb-2 lg:tracking-normal tracking-wide">Sales Inquiry</h2>
            <p className='lg:tracking-normal tracking-wide'>{addresses[0].SalesInquiry.description}</p>
            <form className="mt-4" onSubmit={(event) => handleSubmit(event, 0)}>
              {addresses[0].SalesInquiry.formFields.map((field, index) => (
                <div key={index} className="mb-2">
                  <InputLabel className="block font-semibold">{field.name}</InputLabel>
                  <Input
                    type="text"
                    className="w-full border rounded-lg p-2"
                    value={fieldValues[field.fieldKey] || ''}
                    onChange={(e) =>
                      handleFieldChange(field.fieldKey, e.target.value)
                    }
                  />
                </div>
              ))}
              <button className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4">
                Submit
              </button>
            </form>
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction}>
            <h2 className="text-xl font-semibold mb-2 lg:tracking-normal tracking-wide">Request a Demo</h2>
            <p className='lg:tracking-normal tracking-wide'>{addresses[1].RequestADemo.description}</p>
            <form className="mt-4" onSubmit={(event) => handleSubmit(event, 1)}>
              {addresses[1].RequestADemo.formFields.map((field, index) => (
                <div key={index} className="mb-2">
                  <InputLabel className="block font-semibold">{field.name}</InputLabel>
                  <Input
                    type="text"
                    className="w-full border rounded-lg p-2"
                    value={fieldValues[field.fieldKey] || ''}
                    onChange={(e) =>
                      handleFieldChange(field.fieldKey, e.target.value)
                    }
                  />
                </div>
              ))}
              <button className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4">
                Submit
              </button>
            </form>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction}>
            <h2 className="text-xl font-semibold mb-2 lg:tracking-normal tracking-wide">Customer Support</h2>
            <p className='lg:tracking-normal tracking-wide'>{addresses[2].CustomerSupport.description}</p>
            <div className="mt-4">
              <p className="font-semibold lg:tracking-normal tracking-wide">
                {addresses[2].CustomerSupport.noFormDetail.tagline}
              </p>
              <button className="bg-blue-500 text-white px-4 py-2 rounded-lg mt-4">
                <Link to="mailto:info@invontec.com" className='text-white'>
                  {' '}
                  {addresses[2].CustomerSupport.noFormDetail.button}{' '}
                </Link>
              </button>
            </div>
          </TabPanel>
        </SwipeableViews>
      </Box>
    </div>
  );
}
