import React from 'react';
import images from '../../../../../../constants/images';
import ContactUs from '../../../../../../UI/Buttons/ContactUs/ContactUs';

const ServicesSection = () => {
  return (
    <section id="sap-modules" className="pt-16 pb-16 mt-10 bg-blue-200">
      <div className="container mx-auto">
        <div className="row">
          <div className="offset-md-2 col-md-8 text-center">
            <h4 className="section-title text-black text-2xl">
              <strong>SERVICES</strong> WE OFFER
            </h4>
            <p className="text-white font-size-14 text-xl lg:tracking-normal tracking-wide">
              Here’s how we can help your business optimize operations and bring
              technological reformations.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
          <div className="col-span-1">
            <div className="module-box bg-white p-6 rounded-lg">
              <div className="module-img mb-4">
                <img src={images.sfdc_icon1} />
              </div>
              <span className="lg:tracking-normal tracking-wide module-title text-primary font-bold">
                Salesforce Implementation
              </span>

              <p className="lg:tracking-normal tracking-wide font-size-14 text-gray-700">
                We map business processes with a holistic approach to provide
                scalable Salesforce functionality to enterprises and help them
                adopt and deploy innovative cloud-based business solutions.
              </p>
            </div>
          </div>

          <div className="col-span-1">
            <div className="module-box bg-white p-6 rounded-lg">

                <div className="module-img mb-4">
                  <img src={images.sfdc_icon2} />
                </div>
                <span className="lg:tracking-normal tracking-wide module-title text-primary font-bold">
                  Salesforce AMS
                </span>

              <p className="lg:tracking-normal tracking-wide font-size-14 text-gray-700">
                Clients across industries partner with Invontec to leverage our
                robust support and maintenance services for delivering long-term
                stability, maximized ROI, and minimized total cost of ownership
                and maintenance.
              </p>
            </div>
          </div>

          <div className="col-span-1">
            <div className="module-box bg-white p-6 rounded-lg">

                <div className="module-img mb-4">
                  <img src={images.sfdc_icon3} />
                </div>
                <span className="lg:tracking-normal tracking-wide module-title text-primary font-bold">
                  Development Center
                </span>

              <p className="lg:tracking-normal tracking-wide font-size-14 text-gray-700">
                Our development center is strategically located, follows best
                practices and supports our customers to maintain Quality, Cost,
                Customer Satisfaction and Delivery at an optimum level.
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center mt-4">
          <ContactUs />
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
