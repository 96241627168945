import React from 'react';
import images from '../../../../../../constants/images';
import TwoButtons from '../../../../../../UI/Buttons/TwoButtons/TwoButtons';

const UxAndFioriSection = () => {
  return (
    <div className="pt-16 pb-16 bg-gray-100">
      <div className="container mx-auto">
        <div className="md:flex md:items-center">
          <div className="md:w-1/2 md:p-8">
            <h2 className="lg:tracking-normal tracking-wide text-2xl md:text-3xl font-semibold text-primary mb-4">
              UX And Fiori,{' '}
              <strong className="secondary-blue-color lg:tracking-normal tracking-wide">App for Training</strong>
            </h2>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-base mb-4">
              Good UX design can influence an organization’s profitability based
              on standardization, ease of understanding, and access to business
              processes. The evolution of business process to integrate complex
              scenarios has also increased complexities in user experience,
              making it essential that your SAP partner is experienced in the
              latest SAP Intelligent UX approaches and technologies.
            </p>
            <p className="text-gray-600 text-base mb-6 lg:tracking-normal tracking-wide">
              We offer a full range of UX services that includes UX assessments,
              UX Design and UX enablement and onboarding.
            </p>
            <div className="mt-6">
              <TwoButtons />
            </div>
          </div>
          <div className="md:w-1/2 md:p-8">
            <img src={images.fiori} alt="UX And Fiori" className="w-full mix-blend-multiply" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UxAndFioriSection;
