import React from "react";
import Phone from "../../UI/Phone/Phone";
import images from "../../constants/images";
import BusinessBoost from "../Services/components/BusinessBoost";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const Products = () => {
  const navigate = useNavigate();
  const FirstImages = [
    "https://static.imbibetech.com/invontec/assets/case-study-4/1.png",
    "https://static.imbibetech.com/invontec/assets/case-study-4/2.png",
    "https://static.imbibetech.com/invontec/assets/case-study-4/3.png",
    "https://static.imbibetech.com/invontec/assets/case-study-4/4.png",
  ];
  const SecondImages = [
    "https://static.imbibetech.com/invontec/assets/case-study-3/1.png",
    "https://static.imbibetech.com/invontec/assets/case-study-3/2.png",
    "https://static.imbibetech.com/invontec/assets/case-study-3/3.png",
    "https://static.imbibetech.com/invontec/assets/case-study-3/4.png",
  ];
  const ThirdImages = [
    "https://static.imbibetech.com/invontec/assets/case-study-5/1.png",
    "https://static.imbibetech.com/invontec/assets/case-study-5/2.png",
    "https://static.imbibetech.com/invontec/assets/case-study-5/3.png",
    "https://static.imbibetech.com/invontec/assets/case-study-5/4.png",
  ];
  const Phones = () => {
    return (
      <div className="flex w-full flex-col items-center flex-wrap justify-center gap-2">
        <div className="text-4xl mt-4">
          <h1 className="tracking-wide font-bold">
            <strong>Our </strong>PRODUCTS
          </h1>
        </div>
        <div className="flex w-full flex-wrap justify-around">
          <div className="flex flex-col justify-center items-center p-5">
            <Phone images={FirstImages} />
            <button
              data-ripple-light="true"
              type="button"
              className="select-none rounded-lg bg-primary py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              onClick={() => navigate("/app-for-training")}
            >
              Read More
            </button>
          </div>
          <div className="flex flex-col justify-center items-center p-5">
            <Phone images={SecondImages} />
            <button
              data-ripple-light="true"
              type="button"
              className="select-none rounded-lg bg-primary py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              onClick={() => navigate("/inscan")}
            >
              Read More
            </button>
          </div>
          <div className="flex flex-col justify-center items-center p-5">
            <Phone images={ThirdImages} />
            <button
              data-ripple-light="true"
              type="button"
              className="select-none rounded-lg bg-primary py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
              onClick={() => navigate("/app-for-event")}
            >
              Read More
            </button>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      <Helmet>
        <title>Invontec | Products</title>
        <link rel="canonical" href="https://www.invontec.com/products" />
      </Helmet>
      <div className="overflow-hidden">
        <div className="w-full h-20"></div>

        <div className="w-full ">
          <img
            src={images.aboutBg}
            alt="about"
            className="w-full h-auto"
            data-aos="flip-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="500"
            style={{ width: "100%", height: "125px" }}
          />
        </div>
        <Phones />
        {/* <Faq /> */}
        <BusinessBoost />
      </div>
    </>
  );
};

export default Products;
