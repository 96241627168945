import React from 'react';
import images from '../../../../../../constants/images';
import TalkToExpert from '../../../../../../UI/Buttons/TalkToExpert/TalkToExpert';

const SfdcService = () => {
  return (
    <div className="pt-16 pb-16 bg-gray-100">
      <div className="container mx-auto">
        <div className="md:flex md:items-center">
          <div className="md:w-1/2 md:p-8">
            <h2 className="lg:tracking-normal tracking-wide text-2xl md:text-3xl font-semibold text-primary mb-4">
              SFDC Services &nbsp;
              <strong className="secondary-blue-color lg:tracking-normal tracking-wide">
                Salesforce Integration With SAP
              </strong>
            </h2>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-base mb-4">
              As a trusted Salesforce partner, we bring in deep industry
              insights, world-class global delivery, and value-driven innovation
              in designing, implementing, market-leading SFDC solutions. With an
              experienced team of certified Salesforce experts, we effectively
              map business processes with rapid, scalable deployments enabling
              personalized customer experiences. With an experienced team of
              certified Salesforce experts, we effectively map business
              processes with rapid, scalable deployments enabling personalized
              customer experiences on the pioneering business platform.
            </p>

            <div className="mt-6">
              <TalkToExpert />
            </div>
          </div>
          <div className="md:w-10/12">
            <img
              src={images.sfdc_salesforce}
              alt="UX And Fiori"
              className="w-full mix-blend-multiply"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SfdcService;
