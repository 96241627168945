import React from 'react';
import images from '../../../../constants/images';
import TalkToExpert from '../../../../UI/Buttons/TalkToExpert/TalkToExpert';

const Robotics = () => {
  return (
    <div className="pt-16 pb-16 bg-gray-100">
      <div className="container mx-auto">
        <div className="md:flex md:items-center">
          <div className="md:w-1/2 md:p-8">
            <h2 className="lg:tracking-normal tracking-wide text-2xl md:text-3xl font-semibold text-primary mb-4">
            AUTOMATION & ROBOTICS &nbsp;
              <strong className="secondary-blue-color lg:tracking-normal tracking-wide">
              Make Your Enterprise AI-Ready
              </strong>
            </h2>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-base mb-4">
            We presents a significantly differentiated automation offering in order to make processes leaner without changing them. Supporting businesses with their various needs, we have embarked upon a journey to help our clients to reduce complexities in operations and improve the flexibility of processes to adapt to required business needs.
            </p>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-base mb-4">
            our automation experts use a variety of frameworks, toolkits and methodologies to aid discovery, design, innovation, delivery and support for automation initiatives along with well-defined and tested foundational instruments.
            </p>

            <div className="mt-6">
              <TalkToExpert />
            </div>
          </div>
          <div className="md:w-10/12">
            <img
              src={images.automationHero}
              alt="UX And Fiori"
              className="w-full mix-blend-multiply"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Robotics;
