import React from 'react';
import { FiCheckCircle } from 'react-icons/fi'; // Import icons from react-icons library

const RegionalDevelopment = () => {
  return (
    <section
      id="erp-empower"
      className="pb-16 pt-16 md:pb-24 md:pt-24 bg-gradient-to-tr from-primary to-slate-700 mt-10"
    >
      <div className="container mx-auto">
        <div className="md:flex md:items-center">
          <div className="md:w-1/2 md:pr-12">
            <h3 className="text-3xl md:text-4xl text-white mb-4 lg:tracking-normal tracking-wide">
              Regional Development center <br />
            </h3>
            <p className="text-white text-base md:text-lg mb-4 lg:tracking-normal tracking-wide">
              The scope of services and SLAs are worked out with our customers
              to suit their specific operating requirements. At regular
              operating meetings, the SLAs are monitored, any changes and
              optimizations are planned together with the customer.
            </p>
          </div>
          <div className="md:w-1/2">
            <ul className="list-disc bg-white text-black  font-semibold p-2 rounded-2xl text-base md:text-lg pl-6">
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                Senior Expertise to review the design and Solutions.
              </li>
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                Control the quality of developments and overall delivery.
              </li>
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                Nearshore competency for requirement/design understanding and
                urgent trouble shooting.
              </li>
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                Planning to form a team in few other countries, target
                FY2021/22.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegionalDevelopment;
