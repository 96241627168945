import React from 'react';
import { useDispatch } from 'react-redux';
import { openForm, setFields } from '../../../redux/formSlice';
const TalkToExpert = () => {
  const dispatch = useDispatch();
  const handleOpenForm = (fields, buttonClick) => {
    dispatch(setFields({ fields, buttonClick }));
    dispatch(openForm());
  };
  const talkToExpertFields = [
    {
      name: 'Name',
      type: 'text',
      required: true,
      fieldKey: 'name',
    },
    {
      name: 'Business E-mail',
      type: 'text',
      required: true,
      fieldKey: 'businessEmail',
    },
    {
      name: 'Phone number',
      type: 'number',
      required: true,
      fieldKey: 'phoneNo',
    },
    {
      name: 'Your company',
      type: 'text',
      required: true,
      fieldKey: 'company',
    },
    {
      name: 'Message',
      type: 'textarea',
      required: true,
      fieldKey: 'message',
    },
  ];
  return (
    <button
      className="px-4 py-2 bg-blue-950 text-white font-semibold rounded-xl lg:tracking-normal tracking-wide"
      onClick={() => {
        handleOpenForm(talkToExpertFields, 'talk to expert');
      }}
    >
      {' '}
      Talk To Expert
    </button>
  );
};

export default TalkToExpert;
