import React from 'react';
import images from '../../constants/images';

const Header = ({ title }) => {
  return (
    <div
      alt="about"
      className="w-full h-auto bg-blue-950 flex items-center justify-center mix-blend-darken"
      data-aos="flip-right"
      data-aos-easing="ease-out-cubic"
      data-aos-duration="500"
      style={{
        width: '100%',
        height: '200px',
        background: `url(${images.bannerBg})`,
        // backgroundSize: 'cover', // Set background image to cover the div
        backgroundPosition: 'bottom', // Center the background image
      }}
    >
      <h1 className="font-phudu lg:text-5xl text-xl text-white mt-20 tracking-wider">
        <span className="typing-effect">{title}</span>
      </h1>
    </div>
  );
};

export default Header;
