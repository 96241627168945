import React from 'react';
import { Link } from 'react-router-dom';

const ContactNow = () => {
  return (
    <div className="btn-row mt-4">
      <Link to="/contact-us" className="mr-2">
        <span className="btn bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-4 rounded-full transition duration-300 ease-in-out cursor-pointer">
          Contact Now
        </span>
      </Link>
    </div>
  );
};

export default ContactNow;
