import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>Invontec | Page not found</title>
        <meta name="errorpage" content="true" />
        <meta name="errortype" content="404 - Not Found" />
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <div className="flex h-screen items-center justify-center bg-gray-900">
        <div className="max-w-md text-center">
          <h1 className="text-6xl font-extrabold text-white mb-4">404</h1>
          <p className="text-lg text-gray-300 mb-8">
            Oops! The page you&apos;re looking for doesn&apos;t exist.
          </p>
          <Link
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md transition duration-300"
            to="/"
          >
            Go Back Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default NotFound;
