import React from 'react'
import {useNavigate} from 'react-router-dom'
const ViewAllProducts = () => {
  const navigate = useNavigate()
  return (
    <button className="px-4 py-2 bg-yellow-500 text-white font-semibold rounded-xl" onClick={()=>navigate('/products')}>
    VIEW ALL PRODUCTS
  </button>
  )
}

export default ViewAllProducts