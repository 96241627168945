import React from 'react';
import images from '../../../../../../constants/images';

const SapModulesSection = () => {
  return (
    <section id="sap-modules" className="pt-16 pb-16 mt-10 bg-gray-100">
      <div className="container mx-auto">
        <div className="md:flex md:items-center">
          {/* <div className="md:w-1/2 md:p-12"> */}
            <h4 className="text-2xl md:text-3xl font-semibold text-primary mb-6">
              <strong>What</strong> We Offer
            </h4>
          {/* </div> */}
        </div>
        <div id="technical-expertise-tech" className="flex flex-wrap -mx-4 mt-8">
          <div className="w-full md:w-1/3 px-4 mb-8">
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="module-img mb-4">
                <img src={images.sap_icon1} alt="Recovery & Maintenance" />
              </div>
              <span className="module-title text-lg font-semibold text-primary">
                Recovery &amp; Maintenance
              </span>
              <p className="text-base text-gray-600 mt-2 lg:tracking-normal tracking-wide">
                Leave your security concerns to our experts. We help you minimize risk, reduce potential losses, downtime, and avoid costly incidents with continuous monitoring and granular control. We take due protection and rapid threat resolution before they affect your data and infrastructure.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/3 px-4 mb-8">
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="module-img mb-4">
                <img src={images.sap_icon2} alt="Data Backup" />
              </div>
              <span className="module-title text-lg font-semibold text-primary">
                Data Backup
              </span>
              <p className="text-base text-gray-600 mt-2 lg:tracking-normal tracking-wide">
                Keep your data safe and secure from the growing number of cyber threats and any suspicious activity with multiple authentication layers and intelligence. Our experts take regular backups of your business data for any eventuality if any.
              </p>
            </div>
          </div>
          <div className="w-full md:w-1/3 px-4 mb-8">
            <div className="bg-white rounded-lg shadow-md p-6">
              <div className="module-img mb-4">
                <img src={images.sap_icon3} alt="High-availability of data" />
              </div>
              <span className="module-title text-lg font-semibold text-primary">
                High-availability of data
              </span>
              <p className="text-base text-gray-600 mt-2 lg:tracking-normal tracking-wide">
                We know how to withstand all planned and unplanned outages such as system upgrades, power outage, software errors, data corruption, or unplanned faults. Our experts know how to keep your business up and running with our high-availability solutions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SapModulesSection;
