import React, { useState } from 'react';
import images from '../../constants/images';
import { Navbar } from '@nextui-org/react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/userSlice';
import TemporaryDrawer from './Drawer';

export default function NavBar() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const collapseItems = [
    { title: 'Home', path: '/' },
    { title: 'Services', path: '/services' },
    { title: 'Products', path: '/products' },
    { title: 'About Us', path: '/about' },
    { title: 'Career', path: '/career' },
    { title: 'Contact Us', path: '/contact-us' },
  ];
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const handleLogout = () => {
    dispatch(logout());
  };


  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <div className="overflow-hidden">
      <Navbar
        className="flex items-center font-semibold"
        variant="static"
        css={{
          fontSize: '$sm',
          position: 'fixed',
          backgroundColor: 'White',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Navbar.Brand>
          <div className='lg:hidden flex'>
            <TemporaryDrawer links={collapseItems} />
          </div>
          <img
            src={images.logo}
            alt="invontec-logo"
            width="100"
            height="100"
            className="lg:ml-0 ml-2 hover:cursor-pointer lg:flex hidden"
            onClick={() => { closeMobileMenu(); navigate('/') }}
          ></img>
        </Navbar.Brand>
        <img
          src={images.logo}
          alt="invontec-logo"
          width="100"
          height="100"
          className="lg:ml-0 ml-2 hover:cursor-pointer lg:hidden flex "
          onClick={() => { closeMobileMenu(); navigate('/') }}
        ></img>
        <Navbar.Content
          hideIn="sm"
          className={isMobileMenuOpen ? 'block' : 'hidden'}
        >
          {collapseItems.map((item, i) => (
            <NavLink
              className={({ isActive }) => {
                if (isActive) return " hover:cursor-pointer text-md text-primary"
                return "text-black hover:cursor-pointer text-md hover:text-primary"
              }}
              to={item.path}
              key={i}
              onClick={closeMobileMenu}
            >
              {item.title}
            </NavLink>
          ))}
        </Navbar.Content>

        {isAuthenticated && (
          <div className="flex gap-2 items-center justify-center">
            <NavLink
              to="/info"
              className="px-4 py-2 bg-purple-500 text-white rounded-lg"
            >
              Admin Panel
            </NavLink>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded-lg"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        )}
      </Navbar>
    </div>
  );
}
