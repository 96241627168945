import React from 'react';
import { FiCheckCircle } from 'react-icons/fi'; // Import icons from react-icons library

const ErpEmpowerSection = () => {
  return (
    <section
      id="erp-empower"
      className="pb-16 pt-16 md:pb-24 md:pt-24 bg-gradient-to-tr from-primary to-slate-700 mt-10"
    >
      <div className="container mx-auto">
        <div className="md:flex md:items-center">
          <div className="md:w-1/2 md:pr-12">
            <h3 className="text-3xl md:text-4xl text-white mb-4 lg:tracking-normal tracking-wide">
              We help our customers with <br />
              <strong className="text-secondary-blue-color">
                Business Benefits
              </strong>
            </h3>
            <p className="text-white text-base md:text-lg mb-4 lg:tracking-normal tracking-wide">
              The scope of services and SLAs are worked out with our customers
              to suit their specific operating requirements. At regular
              operating meetings, the SLAs are monitored, any changes and
              optimizations are planned together with the customer.
            </p>
            <p className="text-white text-base md:text-lg mb-4 lg:tracking-normal tracking-wide">
              Companies don’t have to be big to be intelligent enterprises.
              Businesses of every shape and size can leverage the business value
              of ERP to innovate, disrupt, transform, and grow exponentially.
            </p>
          </div>
          <div className="md:w-1/2">
            <ul className="list-disc bg-white text-black  font-semibold p-2 rounded-2xl text-base md:text-lg lg:pl-6">
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                Cost-effective operations support with off-shore and on-shore
                working models. We customize operations support setup as per
                business requirements.
              </li>
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                Structure competence center that fits their business needs and
                allows scaling up in case of changing business needs.
              </li>
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                In-depth analysis of their business process pain points,
                operation issues, and continuous system improvement.
              </li>
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                Ensure compliance of their system operation as per regulations
                and SOX checkpoints.
              </li>
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                Retain their specific business knowledge within our core team of
                Center of Excellence.
              </li>
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                System enhancements in an agile manner and ensure safeguarded
                transition run.
              </li>
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                We help reduce system downtimes to negligible-minimum by
                real-time monitoring of system issues.
              </li>
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                Maintaining service level agreements for our customers. Our
                certified consultants take a proactive approach to software
                support and maintenance, focusing on long-term functionality,
                stability, and preventive maintenance.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErpEmpowerSection;
