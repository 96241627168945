import React, { useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { closeForm, clearFields } from "../../redux/formSlice"; // Update with your actual form slice path
import api from "../../apis/api";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

export default function Form() {
  const customStyles = {
    content: {
      top: "50%",
      color: "#000",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      borderRadius: "10px",
      height: "max-content",
      padding: "2rem",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      background: "rgba(255, 255, 255, 0.9)",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.7)",
      zIndex: 1000,
    },
  };
  //   Modal.setAppElement(document.getElementById('root'));
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.modal.isOpen); // Update with your slice name
  const currentFields = useSelector((state) => state.modal.currentFields);
  const formName = useSelector((state) => state.modal.currentButtonClick);
  const location = useLocation();
  const pathName = location.pathname.split("/").pop();
  const handleClose = () => {
    dispatch(closeForm());
  };
  const [fieldValues, setFieldValues] = useState({});
  const handleFieldChange = (fieldName, value) => {
    setFieldValues((prevFieldValues) => ({
      ...prevFieldValues,
      [fieldName]: value,
    }));
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      await api.Message.createMessage({ ...fieldValues, formName, pathName });
      // Do something with the submitted data (fieldValues)
      toast.success("Message sent successfully!");

      // Close the modal after submission
      dispatch(clearFields());
      setFieldValues({});
      handleClose();
    } catch (error) {
      if (error?.response?.data) return toast.error(error.response.data.error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <button
        onClick={handleClose}
        className="absolute top-4 right-4  bg-red-600 px-4 py-2 text-white font-bold rounded-lg"
      >
        Close
      </button>

      <form className="mt-10 text-black font-bold" onSubmit={handleSubmit}>
        {currentFields.map((field) => (
          <div key={field.name} className="relative z-0 w-full mb-6 group">
            <input
              type={field.type}
              name={`floating_${field.name.toLowerCase()}`}
              id={`floating_${field.name.toLowerCase()}`}
              className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 border-black appearance-none focus:outline-none focus:ring-0 peer  placeholder:text-black peer-placeholder-shown:text-black"
              placeholder={""}
              required={field.required}
              value={fieldValues[field.fieldKey] || ""} // Set the value attribute from local state
              onChange={(e) =>
                handleFieldChange(field.fieldKey, e.target.value)
              } // Update local state
            />
            <label
              htmlFor={`floating_${field.name.toLowerCase()}`}
              className="absolute text-sm duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-black peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 peer-focus:text-black peer-focus:font-bold"
            >
              {field.name}
            </label>
          </div>
        ))}
        <button
          type="submit"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center font-bold"
          disabled={isLoading}
        >
          {isLoading ? "Submitting..." : "Submit"}
        </button>
      </form>
    </Modal>
  );
}
