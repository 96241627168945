import React from "react";
import ContactUs from "../../../../UI/Buttons/ContactUs/ContactUs";
import images from "../../../../constants/images";
const servicesData = [
  {
    title: "Automation Strategy",
    imageUrl: images.AutomationStrategy,
    description:
      "Creating a scalable roadmap for clients on their enterprise-wide automation journey while ensuring long-term success and growth.",
  },
  {
    title: "Automation Consulting",
    imageUrl: images.AutomationConsulting,
    description:
      "Helping clients with key asks from an automation standpoint e.g. tool evaluation, opportunity prioritization, value baselining, and expert implementation guidance.",
  },
  {
    title: "Process Discovery",
    imageUrl: images.ProcessDiscovery,
    description:
      "Identifying high-potential automation opportunities through in-depth process analysis, driving streamlined operations and substantial cost savings.",
  },
  {
    title: "Process Mining",
    imageUrl: images.ProcessMining,
    description:
      "Deep diving into the identified operational areas to meticulously create candidate process catalogues for automation, ensuring a robust foundation for implementation.",
  },
  {
    title: "Business Process Re-engineering",
    imageUrl: images.BusinssProcessReEngineering,
    description:
      "Process study and blueprinting in order to eliminate non-value adding tasks and make the process flexible enough to accommodate a new feature",
  },
  {
    title: "Solution Design and Development",
    imageUrl: images.SolutionDesignAndDevelopment,
    description:
      "Designing and flawlessly executing automation solutions, ensuring adherence to requirements, design, best practices and industry standards.",
  },
  {
    title: "Maintenance and Support",
    imageUrl: images.MaintenanceAndSupport,
    description:
      "Sustaining automations in real time operations ensuring high throughput and minimizing business impact due to faults and failures.",
  },
];

const ServicesSection = () => {
  return (
    <section id="sap-modules" className="pt-16 pb-16 mt-10 bg-blue-100">
      <div className="container mx-auto">
        <div className="row">
          <div className="offset-md-2 col-md-8 text-center">
            <h4 className="section-title text-primary">
              <strong>Services</strong> We Offer
            </h4>
            <p className="text-gray-700 font-size-14 lg:tracking-normal tracking-wide">
              Here’s how we can help your business optimize operations and bring
              technological reformations.
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-8">
          {servicesData.map((service, index) => (
            <div key={index} className="col-span-1">
              <div className="module-box bg-white p-6 rounded-lg">
                <div className="module-img mb-4">
                  <img
                    src={service.imageUrl}
                    alt={service.title}
                    className="aspect-video object-cover"
                  />
                </div>
                <span className="lg:tracking-normal tracking-wide module-title text-primary font-bold">
                  {service.title}
                </span>
                <p className="lg:tracking-normal tracking-wide font-size-14 text-gray-700">
                  {service.description}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center item center">
          <ContactUs />
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;
