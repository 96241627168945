import React from 'react';
import images from '../../../constants/images';

const CareerJoin = () => {
  return (
    <section
      id="career-join"
      className="bg-gray-100 bg-cover bg-center py-10"
      style={{ backgroundImage: `url(${images.careerImage})` }}
    >
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 p-10 bg-white bg-opacity-80">
            <div className="pt-10 pb-10">
              <h4 className="text-2xl md:text-3xl text-blue-600 font-semibold lg:tracking-normal tracking-wide">
                Life is Short, <strong>JOIN US.</strong>
              </h4>
              <p className="text-base md:text-lg text-gray-600 mt-4 lg:tracking-normal tracking-wide">
                Join us as we have within us an unshakeable drive to grow and
                tackle complex challenges. We’re big thinkers who bring new
                perspectives to solve problems, spread cheers, and celebrate
                life. We come every day with a new purpose to learn and create
                an impact through what we do.
              </p>
              <div className="mt-6">
                <a href="#current-opening">
                  <span className="bg-yellow-500 hover:bg-yellow-600 text-white py-2 px-6 rounded-full cursor-pointer transition duration-300">
                    VIEW OPEN POSITIONS
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareerJoin;
