import React from 'react';
import './HeroSection.scss';
import images from '../../../../constants/images';

import { Link } from 'react-router-dom';
const HeroSection = () => { 
  const data = [
    {
      title: 'ERP Service',
      description:
        'Today ERP is required to realize business value in the new digital economy. Without modernization, ERPs are both costly, open to cyber resilience issues and an impediment to digital transformation.',
      image: images.erp,
      link: 'erp-solution-sap',
    },
    {
      title: 'Automation / Robotic',
      description:
        'We presents a significantly differentiated automation offering in order to make processes leaner without changing them.. hout changing them...hout changing them... .',
      image: images.automation,
      link: '/automation',
    },
    {
      title: 'CRM',
      description:
        'As a trusted Salesforce partner, we bring in deep industry insights, world-class global delivery, and value-driven innovation in designing, implementing, market-leading SFDC solutions...',
      image: images.sfdc,
      link: '/sfdc',
    },
    {
      title: 'AMS Management',
      description:
        'To stay competitive in today’s dynamic business environment, companies require uninterrupted operation of services for their enterprise applications. operation of services for their enterprise application',
      image: images.ams,
      link: '/ams-management',
    },
    {
      title: 'Development Center',
      description:
        'Our development center which is statrtegilcy located and support our customer in maintaining QCD. We work with our customer on specific requirements and provide variety of development support .',
      image: images.development,
      link: '/development-center',
    },
    {
      title: 'Management Dashboard',
      description:
        'Invontec experienced consultants bring a combination of deep product expertise, knowledge gained through multiple successful BI implementations and not only the technical skill sets but also the ...',
      image: images.dashboard,
      link: '/dashboard',
    },
  ];
  return (
    <div
      className="flex flex-col justify-center items-center"
      style={{
        backgroundImage: `url(${images.blueBg})`,
      }}
    >
      <div className="w-full h-20"></div>

      < >
        <div className="lg:w-1/5 flex justify-center lg:ml-4 ml-6">
          <img
            src={images.logo2}
            className="mix-blend-screen"
            alt="invontec"
            style={{ width: '200px', height: 'auto' }} // You can adjust the width as needed
          />
        </div>
        <h1
          className="m-50 text-xl text-dynamic text-white text-center font-bold lg:text-left tracking-wider mt-4   "
          style={{
            textShadow: '2px 2px 5px white',
          }}
        >
           &nbsp;&nbsp;&nbsp;Innovation Through Technology
        </h1>
      </>
      <ul className="flex w-5/6 justify-evenly font-bold flex-wrap">
        {data.map((item,i) => {
          return (
            <li className='font-bold' key={i}>
              <Link to={item.link} className='text-sm text-white font-bold'> {item.title}</Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default HeroSection;
