import Aos from "aos";
import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotFound from "./Pages/404";
import Form from "./UI/Form/Form";
import SplashScreen from "./UI/SplashScreen/SplashScreen";
import Footer from "./common/components/Footer";
import NavBar from "./common/components/Navbar";
import ScrollToTop from "./common/components/ScrollToTop";
import { routes } from "./constants/constants";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
    Aos.init({ duration: 600 });
  }, []);

  return (
    <div className="">
      {loading ? (
        <SplashScreen />
      ) : (
        <div className="overflow-hidden">
          <Router>
            <ScrollToTop>
              <NavBar />
              <Form />
              <ToastContainer />
              <Routes>
                {routes.map((route, index) => (
                  <Route
                    key={index}
                    path={route.path}
                    element={<route.component />}
                  />
                ))}
                {/* Handle 404 routes */}
                <Route
                  path="*"
                  element={<NotFound />}
                  caseSensitive={false}
                  onMatch={({ location }) => {
                    if (location.pathname === "/404") return;
                    return <NotFound status={404} />;
                  }}
                />
              </Routes>
              <Footer />
            </ScrollToTop>
          </Router>
        </div>
      )}
    </div>
  );
};

export default App;
