import React from "react";
import BusinessBoost from "../../Services/components/BusinessBoost";
import ApplicationManagement from "./components/ApplicationManagement";
import KeyServiceOfferingsSection from "./components/KeyServiceOfferingsSection";
import BIServiceSection from "./components/BiServiceSection";
import Header from "../../../common/components/Header";
import { Helmet } from "react-helmet";

const Dashboard = () => {
  return (
    <>
      <Helmet>
        <title>Invontec | Dashboard</title>
        <link rel="canonical" href="https://www.invontec.com/dashboard" />
      </Helmet>
      <div>
        <Header title="Management Dashboard" />
        <div className="mt-10 w-full">
          <ApplicationManagement />
          <KeyServiceOfferingsSection />
          <BIServiceSection />
          <BusinessBoost />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
