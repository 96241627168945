import axios from "axios";

// Create an instance of Axios with custom configuration
// const baseURL = "http://localhost:5001/api";
const baseURL = "https://invontec-backend.vercel.app/api";
const instance = axios.create({
  baseURL: baseURL, // Your API base URL
  proxy: {
    host: "your-proxy-host.com", // Proxy host
    port: 8080, // Proxy port
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    const userState = JSON.parse(localStorage.getItem("userState"));
    config.headers["x-access-token"] = userState?.token;

    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Handle successful response
    return response;
  },
  (error) => {
    // Handle error response
    return Promise.reject(error);
  }
);

export default instance;
