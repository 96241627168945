import React from "react";
import images from "../../../../constants/images";

const biServiceData = [
  {
    iconUrl: images.InstantDeployment,
    title: "Instant Deployment",
    description:
      "Get started in no time without any formal training. Gain useful business insights in real time with its intuitive and out-of-the box dashboards.",
  },
  {
    iconUrl: images.TapAnyDataSource,
    title: "Tap any Data Source",
    description:
      "Easily pull in data from any datasource for some quick analysis. Tap into any data source from excel sheet to on-premise database and gain a holistic view of information.",
  },
  {
    iconUrl: images.RealTimInsight,
    title: "Real-time Insight",
    description:
      "Make quick decisions on time-sensitive information, and identify business problems before they arrive with BI dashboards.",
  },
  {
    iconUrl: images.DataVisualization,
    title: "Data Visualization",
    description:
      "Start transforming your data into live dashboards and reports. Visualize your data in the form of stunning, interactive graphs and charts using the smart dashboards.",
  },
  {
    iconUrl: images.ContentCuration,
    title: "Content Curation",
    description:
      "Create & publish personalized data for your team and your entire organization using BI app. Use it to monitor the health of your business. It’s easy, fast and fun!",
  },
  {
    iconUrl: images.DataCollaboration,
    title: "Data Collaboration",
    description:
      "Seamlessly collaborate with key stakeholders to ensure that everyone is using the right data and using it to make fast and reliable decisions.",
  },
  {
    iconUrl: images.AnytimeAnywhere,
    title: "Anytime, Anywhere",
    description:
      "Get a single view of your most critical business data on-the-go with touch-enabled native apps for Windows, iOS and Android.",
  },
  {
    iconUrl: images.SeamlessIntegration,
    title: "Seamless Integration",
    description:
      "Integrate your application or services using Tableau open, standards-based REST API hassle-free while focusing on your core business value.",
  },
  {
    iconUrl: images.PublishAndShare,
    title: "Publish & Share",
    description:
      "Combine data sources, create stunning visuals and publish your data story to web, and reach millions of users quickly.",
  },
];

const BIServiceSection = () => {
  return (
    <section id="our-bi-services" className="pt-20 pb-20 bg-primary">
      <div className="container mx-auto">
        <div className="row">
          <div className="col-md-12 text-center">
            <h4 className="section-title text-white">
              <strong>Explore Endless Possibilities</strong> with Invontec BI
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 justify-center">
              {biServiceData.map((service, index) => (
                <li key={index} className="bg-white p-2">
                  <div className="gl-list-item text-center flex items-center justify-center gap-2">
                    <img
                      src={service.iconUrl}
                      alt={service.title}
                      className="aspect-video object-contain w-1/2"
                    />
                  </div>
                  <p className="lg:tracking-normal tracking-wide font-size-14 text-gray-700 mt-4 text-center">
                    <strong>{service.title}</strong>
                    <br />
                    {service.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BIServiceSection;
