import React from "react";
import images from "../../constants/images";
import OurServices from "../Home/components/OurServices/OurServices";
import Solutions from "../Home/components/Solutions/Solutions";
import BusinessBoost from "./components/BusinessBoost";
import { Helmet } from "react-helmet";
const Services = () => {
  return (
    <>
      <Helmet>
        <title>Invontec | Services</title>
        <link rel="canonical" href="https://www.invontec.com/services" />
      </Helmet>
      <div className="overflow-hidden">
        <div className="  flex justify-center items-center  flex-col gap-5 overflow-hidden">
          <div className="w-full">
            <img
              src={images.service}
              alt="about"
              className="w-full h-auto"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="500"
              style={{ width: "100%", height: "200px" }}
            />
          </div>

          <OurServices />
          <Solutions />
          <BusinessBoost />
        </div>
      </div>
    </>
  );
};

export default Services;
