import React from 'react';
import images from '../../../../constants/images';
import TalkToExpert from '../../../../UI/Buttons/TalkToExpert/TalkToExpert';
import { FiCheckCircle } from 'react-icons/fi'; // Import icons from react-icons library

const Benefits = () => {
  return (
    <div className="pt-16 pb-16 mt-10 bg-gray-100">
      <div className="container mx-auto">
        <div className="md:flex md:items-center">
          <div className="md:w-1/2 md:p-8">
            <h2 className="lg:tracking-normal tracking-wide text-2xl md:text-3xl font-semibold text-primary mb-4">
              We'll help automating your Business,
              <strong className="secondary-blue-color lg:tracking-normal tracking-wide">
                {' '}
                Benefit from the Boost in productivity.
              </strong>
            </h2>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-base mb-4">
              Our offered Salesforce service categories include Salesforce Sales
              Cloud, Salesforce Service Cloud, Marketing Cloud, Salesforce
              Analytics Cloud, Salesforce App cloud, Salesforce Pardot, Field
              Service Solution, CPQ - Quote to Cash and many more.
            </p>

            <ul className="list-disc bg-white text-black  font-semibold p-2 rounded-2xl text-base ">
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                Automate with Salesforce, Boost SALES PRODUCTIVITY by 36%
              </li>
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                Automate with Salesforce, Boost REVENUE by 37%
              </li>
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                Automate with Salesforce, Boost LEADS by 44%
              </li>
              <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
                <span className="text-primary mr-2">
                  <FiCheckCircle />
                </span>
                Automate with Salesforce, Boost CUSTOMER RETENTION by 30%
              </li>
            </ul>

            <div className="mt-6">
              <TalkToExpert />
            </div>
          </div>
          <div className="md:w-10/12 mt-2 lg:mt-0">
            <img src={images.sfdc5} alt="UX And Fiori" className="w-full " />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
