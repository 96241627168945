import React from 'react';
import images from '../../../constants/images';
import TwoButtons from '../../../UI/Buttons/TwoButtons/TwoButtons';

const ErpAddOns = () => {
  return (
    <section
      id="erp-add-ons"
      className="relative p-16 w-full h-[500px] overflow-hidden"
      style={{ background: `url(${images.addOnsBg})` }}
    >
      <div className="container mx-auto relative">
        <div className="md:flex justify-end items-center h-full">
          <div className="md:w-1/2 p-8 bg-white  rounded-lg shadow-lg">
            <div className="md:text-left">
              <h4 className="text-2xl font-semibold text-gray-800 mb-2">
                <strong>"eTAPP"</strong> For All Your Business Needs
              </h4>
              <p className="text-sm text-gray-600 mb-4">
                Effortless access to meeting schedule from your personal Device
                - Using SAP ID
              </p>
              <p className="text-sm text-gray-600 mb-4">
                Single tap access to join the training session via provided
                meeting application.
              </p>
              <p className="text-sm text-gray-600 mb-4">
                Fiori UX to provide same user interface as standard application.
              </p>
              <p className="text-sm text-gray-600 mb-4">Device Independent</p>
              <TwoButtons />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErpAddOns;
