import React from "react";
import ErpSolutions from "./ErpSolutions";
import BusinessBoost from "../../Services/components/BusinessBoost";
import Header from "../../../common/components/Header";
import { Helmet } from "react-helmet";
const ErpServices = () => {
  return (
    <>
      <Helmet>
        <title>Invontec | ERP Solutions SAP</title>
        <link rel="canonical" href="https://www.invontec.com/erp-solution-sap" />
      </Helmet>
      <div className="overflow-hidden">
        <div className="  flex justify-center items-center  flex-col gap-5 overflow-hidden">
          <div className="w-full">
            <Header title="ERP Solutions SAP" />
          </div>
          <div className="flex flex-col items-center  bg-gray-100 lg:p-2">
            <div className=" w-full flex flex-col gap-20 ">
              <ErpSolutions />
            </div>
          </div>
        </div>
        <BusinessBoost />
      </div>
    </>
  );
};

export default ErpServices;
