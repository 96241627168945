import React from "react";
import { Helmet } from "react-helmet";
import { LiaAngleRightSolid } from "react-icons/lia";
import ContactNow from "../../../../../UI/Buttons/ContactNow/ContactNow";
import TwoButtons from "../../../../../UI/Buttons/TwoButtons/TwoButtons";
import Header from "../../../../../common/components/Header";
import images from "../../../../../constants/images";
import ErpSolutions from "../../ErpSolutions";
const S4HANAAssessment = () => {
  return (
    <section id="sap-business" className="for-hana bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <div className="md:flex md:items-center">
          <div className="md:w-1/2 md:pr-12 mb-10 md:mb-0">
            <h4 className="text-2xl font-semibold text-primary mb-4">
              <strong>S/4HANA Assessment and Planning</strong>
            </h4>
            <p className="text-gray-600 text-base mb-6 lg:tracking-normal tracking-wide">
              Enterprises that have made significant investments in SAP ECC are
              understandably hesitant to embark on another SAP transformation
              program. The benefits of transitioning to S/4HANA need to be clear
              with a strong business case and the roadmap aligned to their
              broader strategy.
            </p>

            <ul className="list-disc pl-6 text-gray-600 mb-6 lg:tracking-normal tracking-wide">
              <li className="flex  gap-2 items-center">
                {" "}
                <LiaAngleRightSolid size={10} />{" "}
                <p className="lg:tracking-normal tracking-wide">
                  {" "}
                  Enterprise architecture advisory services
                </p>
              </li>
              <li className="flex  gap-2 items-center">
                {" "}
                <LiaAngleRightSolid size={10} />{" "}
                <p className="lg:tracking-normal tracking-wide">
                  Digital transformation consulting
                </p>{" "}
              </li>
              <li className="flex  gap-2 items-center">
                {" "}
                <LiaAngleRightSolid size={10} />{" "}
                <p className="lg:tracking-normal tracking-wide">
                  {" "}
                  Roadmap and strategy development
                </p>
              </li>
              <li className="flex  gap-2 items-center">
                {" "}
                <LiaAngleRightSolid size={10} />{" "}
                <p className="lg:tracking-normal tracking-wide">
                  {" "}
                  Business case development
                </p>
              </li>
              <li className="flex  gap-2 items-center">
                {" "}
                <LiaAngleRightSolid size={10} />{" "}
                <p className="lg:tracking-normal tracking-wide">
                  {" "}
                  Solution design, build, test, and implementation
                </p>
              </li>
              <li className="flex  gap-2 items-center">
                {" "}
                <LiaAngleRightSolid size={10} />{" "}
                <p className="lg:tracking-normal tracking-wide">
                  {" "}
                  Change management and training
                </p>
              </li>
              <li className="flex  gap-2 items-center">
                {" "}
                <LiaAngleRightSolid size={10} />
                Application support, hosting services, and application
                enhancement
              </li>
            </ul>
            <TwoButtons />
          </div>
          <div className="md:w-1/2">
            <div>
              <img src={images.sapHana} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const SolutionModule = ({ title, imageUrl, description, keyOfferings }) => {
  return (
    <div className="col-md-4">
      <div className="module-box p-6 rounded-lg shadow-md bg-white">
        <div className="module-img mb-4">
          <img
            src={imageUrl}
            alt={title}
            className="aspect-video "
          />
        </div>
        <span className="module-title text-xl font-semibold">{title}</span>
        <p className="text-gray-700 text-base my-3 lg:tracking-normal tracking-wide">
          {description}
        </p>
        <ul className="list-disc pl-6 text-gray-600">
          {keyOfferings?.map((offering, index) => (
            <li
              key={index}
              className="flex items-center lg:tracking-normal tracking-wide"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-primary mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
              {offering}
            </li>
          ))}
        </ul>
        {/* <ContactNow /> */}
      </div>
    </div>
  );
};

const SolutionModulesList = ({ modules }) => {
  return (
    <div className="grid grid-cols-1 gap-8 md:grid-cols-3 mt-8">
      {modules.map((module, index) => (
        <SolutionModule
          key={index}
          title={module.title}
          imageUrl={module.imageUrl}
          description={module.description}
          keyOfferings={module.keyOfferings}
        />
      ))}
    </div>
  );
};

const SolutionModulesSection = ({ modules }) => {
  return (
    <section id="solution-modules" className="bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <SolutionModulesList modules={modules} />
      </div>
      <div className="flex justify-center item center">
        <ContactNow />
      </div>
    </section>
  );
};

const theData = [
  {
    title: "S/4HANA Assessment Service",
    imageUrl: images.S4HANAAssessmentService,
    description:
      "Invontec's Braided Assessment brings together design thinking, problem solving, and proprietary toolsets to help organizations understand why, how, and what it takes to transition to S/4 strategically and tactically.",
    keyOfferings: [
      "Case for Change",
      "Architecture (incl. System, UX, Analytics, Data)",
      "Roadmap and Business Case",
    ],
  },
  {
    title: "Accelerated S/4HANA Implementation",
    imageUrl: images.SapsS4HANNA,
    description:
      "Accelerate your implementation of S/4HANA Digital Core, in collaboration with robust CRM integration, utilizing a 'Fit to Standard' approach for maximum efficiency. Benefit from HCL's specialized Base90 or SAP Model Companies, meticulously designed for unique industry-specific needs. This tailored offering is ideal for enterprises seeking to swiftly establish a dynamic digital core for newly acquired ventures or to revitalize existing business units, ensuring a future-ready, seamlessly integrated transformation.",
  },
  {
    title: "S/4 Conversion using Factory+",
    imageUrl: images.S4ConversionUsingFactory,
    description:
      "We will expertly and securely expedite your current SAP ECC systems migration to S/4HANA with our low-risk factory approach. We provide simplification experts, automated code remediation, and automated data transformation for a smooth transition on-premises or to the cloud platform — all in a single step. The base offering can be supplemented with additional services based on your specific needs, including services in business partner conversion or additional Fiori activation.",
  },
];

const S4HanaAssessmentAndPlaning = () => {
  return (
    <>
      <Helmet>
        <title>Invontec | S/4HANA Assessment and Planning</title>
        <link rel="canonical" href="https://www.invontec.com/S-4HANA-assessment-and-planning" />
      </Helmet>
      <div>
        <div className="w-full">
          <Header title="S/4-HANA A Gssessment And Planning" />
        </div>
        <div className="mt-10 w-full">
          <S4HANAAssessment />
          <div className="mt-20">
            <SolutionModulesSection modules={theData} />
          </div>
          <div className="mt-20">
            <ErpSolutions />
          </div>
        </div>
      </div>
    </>
  );
};

export default S4HanaAssessmentAndPlaning;
