import React from 'react';
import images from '../../../../../../constants/images'

const ImplementationSection = () => {
  return (
    <section id="add-on-section" className="bg-gray-100 py-20 mt-10">
      <div className="container mx-auto">
        <div className="text-center">
          <h2 className="text-3xl font-semibold text-primary mb-4">
            Many Successful{' '}
            <span className="text-blue-500">Implementations</span>
          </h2>
          <p className="text-gray-600 text-base mb-8">
            We work on SAP certified Accelerated SAP or ASAP model of
            implementation. With a team of certified SAP consultants, we have
            undertaken many ERP implementations and global rollouts.
          </p>
        </div>
        <div className="flex flex-col md:flex-row mt-12">
          <div className="md:w-1/2">
            <img
              src={images.implementationAddOn}
              className="md:mx-auto"
              alt="sap erp implementation"
            />
          </div>
          <div className="md:w-1/2 md:ml-10">
            <h3 className="text-2xl font-semibold text-primary mb-4">
              <span className="text-blue-500">How we do it?</span>
            </h3>
            <ul className="list-disc pl-6 text-gray-600">
              <li className="mb-4">
                <p className="font-semibold text-blue-500 lg:tracking-normal tracking-wide">Analyze</p>
                <p className="lg:tracking-normal tracking-wide text-sm text-gray-700">
                  Analyze business processes and key requirements. Ensure
                  consistent communication and collaboration to gain
                  organization-wide clarity, budget, and timelines.
                </p>
              </li>
              <li className="mb-4">
                <p className="font-semibold text-blue-500 lg:tracking-normal tracking-wide">
                  Build &amp; Implement
                </p>
                <p className="lg:tracking-normal tracking-wide text-sm text-gray-700">
                  Install and configure the application with minimum business
                  disruption and zero risk. Perform software configuration,
                  system, and integration testing.
                </p>
              </li>
              <li className="mb-4">
                <p className="font-semibold text-blue-500 lg:tracking-normal tracking-wide">
                  Transition &amp; Support
                </p>
                <p className="lg:tracking-normal tracking-wide text-sm text-gray-700">
                  Help your business climb the learning curve with timely and
                  robust support. Assist in the smooth transition from a
                  project-oriented, pre-production to a successful live
                  operation.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ImplementationSection;
