import React from 'react';

const JobTable = ({
  jobs,
  editingJobId,
  handleJobChange,
  handleJobEditClick,
  handleJobSaveClick,
  handleJobDelete,
  setEditingJobId,
  toggleDateEditing,
  formatDate,
  setShowJobModal,
  editingDateField
}) => {
  return (
    <div className="p-10 w-full">
      <div className="flex w-full gap-10 p-2 items-center">
        <h2 className="text-2xl font-bold ">Job Information</h2>
        <button
          onClick={() => setShowJobModal(true)}
          className="px-4 py-2 bg-blue-600 text-white rounded-xl font-bold"
        >
          Create Job
        </button>
      </div>
      <div className="overflow-x-auto w-full">
        <table className="border-collapse border overflow-x-auto">
          <thead className="bg-gray-200">
            <tr className="border-b">
              <th className="border px-4 py-2">Job Title</th>
              <th className="border px-4 py-2">Last Date</th>
              <th className="border px-4 py-2">Job Type</th>
              <th className="border px-4 py-2">Min Qualification</th>
              <th className="border px-4 py-2">Experience</th>
              <th className="border px-4 py-2">Location</th>
              <th className="border px-4 py-2">Job description</th>
              <th className="border px-4 py-2">Additional Information</th>
              {/* <th className="border px-4 py-2">Salary</th> */}
              <th className="border px-4 py-2">isActive</th>
              <th className="border px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {jobs.map((job) => (
              <tr key={job._id} className="border-b">
                <td className="border px-4 py-2">
                  <input
                    value={job.title}
                    readOnly={editingJobId !== job._id}
                    className={`${
                      editingJobId !== job._id ? '' : 'border-2 border-black'
                    } capitalize`}
                    onChange={(e) => {
                      handleJobChange(e, 'title', job._id);
                    }}
                  />
                </td>
                <td className="border px-4 py-2">
                  {editingDateField ? (
                    <input
                      type="date"
                      name="lastDateToApply"
                      className="bg-gray-50 border border-gray-300 text-black text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                      required
                      value={job.lastDateToApply}
                      onChange={(e) =>
                        handleJobChange(e, 'lastDateToApply', job._id)
                      }
                      readOnly={editingJobId !== job._id}
                      onBlur={toggleDateEditing}
                    />
                  ) : (
                    <input
                      type="text"
                      name="lastDateToApply"
                      className={`${
                        editingJobId !== job._id ? '' : 'border-2 border-black'
                      } capitalize`}
                      required
                      readOnly={editingJobId !== job._id}
                      value={formatDate(job.lastDateToApply)}
                      onClick={toggleDateEditing}
                    />
                  )}
                </td>
                <td className="border px-4 py-2">
                  <input
                    value={job.type}
                    readOnly={editingJobId !== job._id}
                    className={`${
                      editingJobId !== job._id ? '' : 'border-2 border-black'
                    } capitalize`}
                    onChange={(e) => {
                      handleJobChange(e, 'type', job._id);
                    }}
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    value={job.minQualification}
                    readOnly={editingJobId !== job._id}
                    className={`${
                      editingJobId !== job._id ? '' : 'border-2 border-black'
                    } capitalize`}
                    onChange={(e) => {
                      handleJobChange(e, 'minQualification', job._id);
                    }}
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    value={job.experience}
                    readOnly={editingJobId !== job._id}
                    className={`${
                      editingJobId !== job._id ? '' : 'border-2 border-black'
                    } capitalize`}
                    onChange={(e) => {
                      handleJobChange(e, 'experience', job._id);
                    }}
                  />
                </td>
                <td className="border px-4 py-2">
                  <input
                    value={job.location}
                    readOnly={editingJobId !== job._id}
                    className={`${
                      editingJobId !== job._id ? '' : 'border-2 border-black'
                    } capitalize`}
                    onChange={(e) => {
                      handleJobChange(e, 'location', job._id);
                    }}
                  />
                </td>
                <td className="border px-4 py-2">
                  <textarea
                    value={job.description}
                    readOnly={editingJobId !== job._id}
                    className={`${
                      editingJobId !== job._id ? '' : 'border-2 border-black'
                    } capitalize`}
                    onChange={(e) => {
                      handleJobChange(e, 'description', job._id);
                    }}
                  />
                </td>
                <td className="border px-4 py-2">
                  <textarea
                    value={job.additionalInformation}
                    readOnly={editingJobId !== job._id}
                    className={`${
                      editingJobId !== job._id ? '' : 'border-2 border-black'
                    } capitalize`}
                    onChange={(e) => {
                      handleJobChange(e, 'additionalInformation', job._id);
                    }}
                  />
                </td>
                {/* <td className="border px-4 py-2">
                        <input
                          value={job.salary}
                          readOnly={editingJobId !== job._id}
                          className={`${
                            editingJobId !== job._id
                              ? ''
                              : 'border-2 border-black'
                          } capitalize`}
                          onChange={(e) => {
                            handleJobChange(e, 'salary', job._id);
                          }}
                        />
                      </td> */}
                <td className="border px-4 py-2">
                  <select
                    value={job.isActive}
                    onChange={(e) => handleJobChange(e, 'isActive', job._id)}
                    disabled={editingJobId !== job._id}
                    className={`${
                      editingJobId !== job._id ? '' : 'border-2 border-black'
                    } capitalize`}
                  >
                    <option value={true} className="capitalize">
                      Yes
                    </option>
                    <option value={false} className="capitalize">
                      No
                    </option>
                  </select>
                </td>

                <td className="border px-4 py-2">
                  {editingJobId !== job._id ? (
                    <div className="flex gap-2">
                      <button
                        onClick={() => handleJobEditClick(job._id)}
                        className="bg-blue-700 px-2 py-1 text-white font-bold rounded-lg"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleJobDelete(job._id)}
                        className="bg-red-700 px-2 py-1 text-white font-bold rounded-lg"
                      >
                        Delete
                      </button>
                    </div>
                  ) : (
                    <div className="flex gap-2">
                      <button
                        onClick={() => handleJobSaveClick(job)}
                        className="bg-blue-700 px-2 py-1 text-white font-bold rounded-lg"
                      >
                        Save
                      </button>
                      <button
                        onClick={() => setEditingJobId(null)}
                        className="bg-red-700 px-2 py-1 text-white font-bold rounded-lg"
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JobTable;
