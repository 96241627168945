import React from "react";
import ErpSolutions from "../../ErpSolutions";
import images from "../../../../../constants/images";
import { LiaAngleRightSolid } from "react-icons/lia";
import ImplementationSection from "./components/SuccessfulImplementation";
import CaseStudySection from "./components/CaseStudySection";
import TwoButtons from "../../../../../UI/Buttons/TwoButtons/TwoButtons";
import BusinessBoost from "../../../../Services/components/BusinessBoost";
import Header from "../../../../../common/components/Header";
import { Helmet } from "react-helmet";
const S4HANAAssessment = () => {
  return (
    <section id="sap-business" className="for-hana bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <div className="md:flex md:items-center">
          <div className="md:w-1/2 md:pr-12 mb-10 md:mb-0">
            <h4 className="text-2xl font-semibold text-primary mb-4">
              <strong>S/4HANA Transformation and Implementation</strong>
            </h4>
            <p className="text-gray-600 text-base mb-6 lg:tracking-normal tracking-wide ">
              Leveraging the capabilities of the next-generation SAP S/4HANA
              business solutions suite, our Enterprise Transformation
              specialists can help you transform operational performance across
              finance, supply chain, customer relations, and other key
              functions.Leveraging the capabilities of the next-generation SAP
              S/4HANA business solutions suite, our Enterprise Transformation
              specialists can help you transform operational performance across
              finance, supply chain, customer relations, and other key
              functions.
            </p>
            <p className="text-gray-600 text-base mb-6 lg:tracking-normal tracking-wide">
              We help you migrate from legacy database systems to the SAP
              —either on premises or in the cloud empowering data-gathering and
              analysis, enabling dynamic real-time reporting, and leveraging new
              insights for better decision-making. Our user-friendly,
              design-thinking principles for SAP deployment can allow you to
              scale up capabilities to address your evolving business needs.
            </p>
            <p className="text-gray-600 text-base mb-6 lg:tracking-normal tracking-wide">
              Invontec delivers high business value S/4HANA solutions to
              enterprises. We can accelerate your S/4 HANA journey by providing
              full-lifecycle, integrated SAP S/4HANA enterprise services,
              including:
            </p>

            <ul className="list-disc pl-6 text-gray-600 mb-6">
              <li className="flex  gap-2 items-center">
                {" "}
                <LiaAngleRightSolid size={10} />{" "}
                <p className="lg:tracking-normal tracking-wide">
                  Business case development
                </p>
              </li>
              <li className="flex  gap-2 items-center">
                {" "}
                <LiaAngleRightSolid size={10} />{" "}
                <p className="lg:tracking-normal tracking-wide">
                  Enterprise architecture advisory services
                </p>{" "}
              </li>
              <li className="flex  gap-2 items-center">
                {" "}
                <LiaAngleRightSolid size={10} />{" "}
                <p className="lg:tracking-normal tracking-wide">
                  {" "}
                  Roadmap and strategy development
                </p>
              </li>

              <li className="flex  gap-2 items-center">
                {" "}
                <LiaAngleRightSolid size={10} />{" "}
                <p className="lg:tracking-normal tracking-wide">
                  {" "}
                  Solution design, build, test, and implementation
                </p>
              </li>
              <li className="flex  gap-2 items-center">
                {" "}
                <LiaAngleRightSolid size={10} />{" "}
                <p className="lg:tracking-normal tracking-wide">
                  {" "}
                  Change management and training
                </p>
              </li>
            </ul>
            <TwoButtons />
          </div>
          <div className="md:w-1/2">
            <div>
              <img src={images.sapHana} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const S4HANATransformationAndImplementation = () => {
  return (
    <>
      <Helmet>
        <title>Invontec | S/4HANA Transformation and Implementation</title>
        <link
          rel="canonical"
          href="https://www.invontec.com/S-4HANA-transformation-and-Implementation"
        />
      </Helmet>
      <div>
        <div className="w-full">
          <Header title="S/4-HANA Transformation and Implementation" />
        </div>
        <div className="mt-10 w-full">
          <S4HANAAssessment />
          <ImplementationSection />
          <CaseStudySection />
          <div className="mt-4">
            <ErpSolutions />
          </div>
          <div className="mt-20">
            <BusinessBoost />
          </div>
        </div>
      </div>
    </>
  );
};

export default S4HANATransformationAndImplementation;
