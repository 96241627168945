import React from 'react';
import './SplashScreen.css';
const SplashScreen = () => {
  return (
    <div className="flex items-center justify-center h-screen w-full">
      <div className="spinner"></div>
    </div>
  );
};

export default SplashScreen;
