import React from "react";
import ErpSolutions from "../../ErpSolutions";
import Header from "../../../../../common/components/Header";
import BusinessBoost from "../../../../Services/components/BusinessBoost";
import TopServerSection from "./components/TopServerSection";
import ErpEmpowerSection from "./components/ErpEmpowerSection";
import SapModulesSection from "./components/SapModuleSection";
import OurBiServicesSection from "./components/OurBiServiceSection";
import { Helmet } from "react-helmet";

const SapAmsService = () => {
  return (
    <>
      <Helmet>
        <title>Invontec | SAP AMS Services</title>
        <link rel="canonical" href="https://www.invontec.com/sap-ams-services" />
      </Helmet>
      <div>
        <div className="w-full">
          <Header title="SAP AMS Service" />
        </div>
        <div className="mt-10 w-full">
          <TopServerSection />
          <ErpEmpowerSection />
          <SapModulesSection />
          <OurBiServicesSection />
          <div className="mt-4">
            <ErpSolutions />
          </div>
          <div className="mt-20">
            <BusinessBoost />
          </div>
        </div>
      </div>
    </>
  );
};

export default SapAmsService;
