import http from './http';

const get = (url, params) => http.get(url, { params });

const post = (url, data) => http.post(url, data);

const put = (url, data) => http.put(url, data);

const del = (url) => http.delete(url);

const Auth = {
  login: (data) => post('/login', data),
  signup: (data) => post('/signup', data),
  users: () => get('/users'),
  updateUser: (data, params = '') => put('/update/' + params, data),
};

const Job = {
  createJob: (data) => post('/job/create', data),
  getAllJobs: () => get('/job/getAll'),
  getJobById: (jobId) => get(`/job/${jobId}`),
  updateJobById: (jobId, data) => put(`/job/${jobId}`, data),
  deleteJobById: (jobId) => del(`/job/${jobId}`),
};

const Message = {
  createMessage: (data) => post('/message/add', data),
  getAllMessages: () => get('/message/all'),
};

const Candidate = {
  createCandidate: (data) =>
    http.post('/apply/add', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }),
  getAllCandidates: () => get('/resumes/all'),
};
export default {
  Auth,
  Job,
  Message,
  Candidate,
};
