import React from 'react';
import Box from '@mui/material/Box';
import images from '../../../constants/images';
import { Link } from 'react-router-dom';
function AddressCard({ address }) {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
      <h2 className="text-xl font-semibold mb-2 tracking-wider  ">
        {address.companyName}
      </h2>
      <p className="mb-2 lg:tracking-normal tracking-wide">{address.address.line1}</p>
      <p className="mb-2 lg:tracking-normal tracking-wide">{address.address.line2}</p>
      <p className="mb-4 lg:tracking-normal tracking-wide">{address.address.line3}</p>
      {address.email && (
        <Link to={'mailto:info@invontec.com'} className="text-blue-500 font-semibold mb-2">
          Email: {address.email}
        </Link>
      )}
      {/* {address.mapLink && (
        <div className="aspect-w-16 aspect-h-9">
          <iframe
            title={`${address.companyName} Map`}
            src={address.mapLink}
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen
            className="rounded-lg shadow-md"
          ></iframe>
        </div>
      )} */}

    </div>
  );
}

export default function Locations() {

  const addresses = [
    {
      Singapore: {
        companyName: 'Invontec Consulting Pte Ltd.',
        mapLink:
          'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d63819.55132405849!2d103.88557500000002!3d1.343223!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da1794213a9493%3A0x1d00f7ad8efa3e03!2sSingapore%20536209!5e0!3m2!1sen!2sus!4v1620387758775!5m2!1sen!2sus',
        address: {
          line1: '23 New Industrial Road #04-08, ',
          line2: 'Solictic Business Center,',
          line3: 'Singapore – 536 209',
        },
        phoneNo: '+65 90021506',
        email: 'info@invontec.com',
      },
    },
    {
      Karnal: {
        companyName: 'Invontec InfoSoft Pvt Ltd.',
        address: {
          line1: '174-R,1-2 Floor',
          line2: 'Model Town, Karnal, 132001,',
          line3: 'Haryana, India',
        },
        mapLink:
        'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13862.641465168415!2d76.9968994!3d29.700625!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2d31971f19c731c8!2sInvontec!5e0!3m2!1sen!2sin!4v1644555064898!5m2!1sen!2sin',
        phoneNo: '+91 9050550567',
        email: 'info@invontec.com',
      },
    },
    {
      Hyderabad: {
        companyName: 'Invontec InfoSoft Pvt Ltd.',
        address: {
          line1: '3rd Floor, HIG-216, Phase 1 & 2,',
          line2: 'Kukatpally Housing Board Colony,',
          line3: 'Hyderabad, Telangana – 500085 India',
        },
        mapLink: '',
        phoneNo: '+91 9050550567',
        email: 'info@invontec.com',
      },
    },
  ];

  return (
    <div className="bg-gray-100  pt-2 flex justify-center items-center w-full flex-col">
      <Box className=" rounded-lg mt-4 flex w-full justify-evenly lg:flex-row flex-col">
        {addresses.map((address, index) => (
          <AddressCard key={index} address={Object.values(address)[0]} />
        ))}
      </Box>
      <div className="aspect-w-16 aspect-h-9 w-2/3">
         <img src={images.map} alt="map" />
        </div>
    </div>

  );
}
