import React, { useState } from "react";
import images from "../constants/images";
import { Link, Navigate } from "react-router-dom";
import api from "../apis/api";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/userSlice";
import { Helmet } from "react-helmet";
const Login = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  if (isAuthenticated) return <Navigate to={"/info"} />;
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      if (!username) {
        return toast.error("Please enter username");
      }
      if (!password) {
        return toast.error("Please enter password");
      }
      const res = await api.Auth.login({
        username,
        password,
      });
      toast.success("Login successful");
      dispatch(login(res.data));
    } catch (error) {
      if (error?.response?.data) return toast.error(error.response.data.error);
      toast.error(error.message);
    }
  };
  return (
    <>
      <Helmet>
        <title>Invontec | Login</title>
        <link rel="canonical" href="https://www.invontec.com/login" />
      </Helmet>
      <section className="bg-gray-50 font-bold ">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <Link
            to="/"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 "
          >
            <img className=" h-8 mr-2" src={images.logo} alt="logo" />
          </Link>
          <div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0  ">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                Sign in to your account
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={handleLogin}>
                <div>
                  <label
                    htmlFor="username"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Your username
                  </label>
                  <input
                    type="text"
                    name="username"
                    id="username"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 "
                    placeholder="username"
                    required=""
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 "
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5      "
                    required=""
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>

                <button
                  type="submit"
                  className="w-full text-black bg-primary text-white hover:bg-primary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                >
                  Sign in
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
