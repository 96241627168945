import React from "react";
import BusinessBoost from "../../../../Services/components/BusinessBoost";
import UxAndFioriSection from "./components/UxAndFioriSection";
import ServicesSection from "./components/ServicesSection";
import TrainingManagementSection from "./components/TrainingManagementSection";
import Header from "../../../../../common/components/Header";
import { Helmet } from "react-helmet";

const UxFiori = () => {
  return (
    <>
      <Helmet>
        <title>Invontec | UX And Fiori, App for Training</title>
        <link rel="canonical" href="https://www.invontec.com/ux-and-fiori-app-for-training" />
      </Helmet>
      <div>
        <div className="w-full">
          <Header title={"UX and Fiori App For Training"} />
        </div>
        <div className="mt-10 w-full">
          <UxAndFioriSection />
          <ServicesSection />
          <TrainingManagementSection />
          <BusinessBoost />
        </div>
      </div>
    </>
  );
};

export default UxFiori;
