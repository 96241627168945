import AbapJavaNetCCPPUI5 from "../assets/images/ABAPJAVA.netCC+UI5Fiori.png";
import AnytimeAnywhere from "../assets/images/Anytime, Anywhere.png";
import AutomationConsulting from "../assets/images/Automation Consulting.png";
import AutomationStrategy from "../assets/images/Automation Strategy.png";
import BusinssProcessReEngineering from "../assets/images/Business Process Re-engineering.png";
import Configure from "../assets/images/Configure.png";
import ContentCuration from "../assets/images/Content Curation.png";
import DataCollaboration from "../assets/images/Data Collaboration.png";
import DataVisualization from "../assets/images/Data Visualization.png";
import Develop from "../assets/images/Develop.png";
import HighAvailability from "../assets/images/High Availability.png";
import InstantDeployment from "../assets/images/Instant Deployment.png";
import Integrate from "../assets/images/Integrate.png";
import MaintenanceAndSupport from "../assets/images/Maintenance and Support.png";
import PowerBiQlikView from "../assets/images/Power BI Qlik view.png";
import ProcessDiscovery from "../assets/images/Process Discovery.png";
import ProcessMining from "../assets/images/Process Mining.png";
import PublishAndShare from "../assets/images/Publish & Share.png";
import RealTimInsight from "../assets/images/Real-time Insight.png";
import S4ConversionUsingFactory from "../assets/images/S4 Conversion using Factory.png";
import S4HANAAssessmentService from "../assets/images/S4HANA Assessment Service.png";
import S4HANATransformationAndImplementation from "../assets/images/S4HANA Transformation and Implementation.png";
import S4HANAAssessmentAndPlanning from "../assets/images/S4HANAAssessmentAnd Planning.png";
import SapAmsServices from "../assets/images/SAP AMS Services.png";
import SapsS4HANNA from "../assets/images/SAPS4HANNA.png";
import SfdcAndSapIntegration from "../assets/images/SFDC and SAP integration.png";
import SeamlessIntegration from "../assets/images/Seamless Integration.png";
import SolutionDesignAndDevelopment from "../assets/images/Solution Design and Development.png";
import StaffAugmentation from "../assets/images/Staff Augmentation.png";
import TapAnyDataSource from "../assets/images/Tap any Data Source.png";
import Tester from "../assets/images/Tester.png";
import Testing from "../assets/images/Testing.png";
import Training from "../assets/images/Training.png";
import UIPathBluePrismChatBotsPython from "../assets/images/UI path blue prism Chat Bots Python.png";
import UIandFioriAppForTraining from "../assets/images/UX and Fiori, App for Training.png";
import aboutBg from "../assets/images/about.jpg";
import aboutHeader from "../assets/images/about.png";
import about from "../assets/images/about.webp";
import addOnsBg from "../assets/images/add-ons-bg.png";
import amsOverview from "../assets/images/ams-overview.png";
import ams from "../assets/images/ams.png";
import automationBanner from "../assets/images/automation-banner.jpg";
import automationHero from "../assets/images/automation.jpg";
import automation from "../assets/images/automation.png";
import bannerBg from "../assets/images/bannerBG.jpg";
import biservice from "../assets/images/biservice.png";
import blueBg from "../assets/images/blueBg.jpeg";
import blueIcon1 from "../assets/images/blueIcon1.png";
import blueIcon2 from "../assets/images/blueIcon2.png";
import blueIcon3 from "../assets/images/blueIcon3.png";
import careerImage from "../assets/images/career-img.png";
import careerBg from "../assets/images/careerbg.jpg";
import centerlogo from "../assets/images/centerlogo.png";
import contactUs from "../assets/images/contactUs.jpg";
import dashboard from "../assets/images/dashboard.png";
import developmentCenter from "../assets/images/development-center.png";
import development from "../assets/images/development.png";
import DisasterRecovery from "../assets/images/disaster-recovery.png";
import erpFaq from "../assets/images/erp-faq.png";
import erp from "../assets/images/erp.png";
import erpSolution from "../assets/images/erpSolution.jpg";
import fiori from "../assets/images/fiori.jpg";
import hero1 from "../assets/images/hero1.jpg";
import hero10 from "../assets/images/hero10.jpeg";
import hero11 from "../assets/images/hero11.jpeg";
import hero12 from "../assets/images/hero12.jpeg";
import hero13 from "../assets/images/hero13.jpeg";
import hero2 from "../assets/images/hero2.jpeg";
import hero3 from "../assets/images/hero3.jpeg";
import hero4 from "../assets/images/hero4.jpeg";
import hero5 from "../assets/images/hero5.jpeg";
import hero6 from "../assets/images/hero6.jpeg";
import hero7 from "../assets/images/hero7.jpeg";
import hero8 from "../assets/images/hero8.jpeg";
import hero9 from "../assets/images/hero9.jpeg";
import icon1 from "../assets/images/icon1.png";
import icon2 from "../assets/images/icon2.png";
import icon3 from "../assets/images/icon3.png";
import icon4 from "../assets/images/icon4.png";
import icon5 from "../assets/images/icon5.png";
import icon6 from "../assets/images/icon6.png";
import image2 from "../assets/images/image2.png";
import implementation from "../assets/images/implementation.jpg";
import implementationAddOn from "../assets/images/implementationAddOn.png";
import logo from "../assets/images/logo.jpeg";
import logo2 from "../assets/images/logo2.jpeg";
import map from "../assets/images/map.jpeg";
import monitor from "../assets/images/monitor.png";
import MonitoringAndSupport from "../assets/images/monitoring.png";
import power_bi from "../assets/images/power-bi.png";
import quest from "../assets/images/quest.png";
import salesforce from "../assets/images/salesforce.png";
import sap_icon1 from "../assets/images/sap-icon1.png";
import sap_icon2 from "../assets/images/sap-icon2.png";
import sap_icon3 from "../assets/images/sap-icon3.png";
import sapHana from "../assets/images/sapHana.png";
import server from "../assets/images/server.png";
import service from "../assets/images/service.jpg";
import sfdc_sap from "../assets/images/sfdc-sap.jpg";
import sfdc from "../assets/images/sfdc.jpg";
import sfdc4 from "../assets/images/sfdc4.jpg";
import sfdc5 from "../assets/images/sfdc5.jpg";
import sfdc_icon1 from "../assets/images/sfdc_icon1.png";
import sfdc_icon2 from "../assets/images/sfdc_icon2.png";
import sfdc_icon3 from "../assets/images/sfdc_icon3.png";
import sfdc_salesforce from "../assets/images/sfdc_salesforce.png";
import sfdcbg from "../assets/images/sfdcbg.jpg";
import umbrella from "../assets/images/umbrella.png";
import uxbg from "../assets/images/uxbg.jpg";
import video from "../assets/images/video.mp4";
import wave from "../assets/images/wave.png";
export default {
  S4HANAAssessmentService,
  SapsS4HANNA,
  Configure,
  InstantDeployment,
  TapAnyDataSource,
  RealTimInsight,
  DataCollaboration,
  ContentCuration,
  DataVisualization,
  AnytimeAnywhere,
  SeamlessIntegration,
  PublishAndShare,
  Develop,
  monitor,
  Integrate,
  HighAvailability,
  StaffAugmentation,
  DisasterRecovery,
  Testing,
  Training,
  MonitoringAndSupport,
  AbapJavaNetCCPPUI5,
  PowerBiQlikView,
  UIPathBluePrismChatBotsPython,
  Tester,
  AutomationStrategy,
  AutomationConsulting,
  ProcessDiscovery,
  ProcessMining,
  BusinssProcessReEngineering,
  SolutionDesignAndDevelopment,
  MaintenanceAndSupport,
  S4ConversionUsingFactory,
  S4HANATransformationAndImplementation,
  SapAmsServices,
  UIandFioriAppForTraining,
  SfdcAndSapIntegration,
  bannerBg,
  blueIcon1,
  blueIcon3,
  video,
  blueIcon2,
  automationBanner,
  implementationAddOn,
  centerlogo,
  wave,
  image2,
  logo2,
  map,
  blueBg,
  power_bi,
  developmentCenter,
  amsOverview,
  sfdcbg,
  sfdc4,
  sfdc5,
  automationHero,
  logo,
  salesforce,
  about,
  erp,
  automation,
  ams,
  dashboard,
  development,
  sfdc,
  aboutBg,
  quest,
  aboutHeader,
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
  icon6,
  erpFaq,
  sapHana,
  addOnsBg,
  careerImage,
  service,
  careerBg,
  contactUs,
  hero1,
  hero2,
  hero3,
  hero4,
  hero5,
  hero6,
  hero7,
  hero8,
  hero9,
  hero10,
  hero11,
  hero12,
  hero13,
  umbrella,
  erpSolution,
  implementation,
  server,
  sap_icon1,
  sap_icon2,
  sap_icon3,
  biservice,
  uxbg,
  fiori,
  sfdc_sap,
  sfdc_salesforce,
  sfdc_icon1,
  sfdc_icon2,
  sfdc_icon3,
  S4HANAAssessmentAndPlanning,
};
