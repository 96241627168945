import React from "react";
import images from "../../../../constants/images";

const serviceOfferings = [
  {
    title: "Configure",
    imageUrl: images.Configure,
    description: "Business Intelligence software to meet your unique needs.",
  },
  {
    title: "Develop",
    imageUrl: images.Develop,
    description:
      "Customized software to meet your exact industry and business demands.",
  },
  {
    title: "Monitor",
    imageUrl: images.monitor,
    description:
      "Ensure your BI software is running without any performance glitch and breakdown.",
  },
  {
    title: "Integrate",
    imageUrl: images.Integrate,
    description:
      "Integrate BI into your other back office, ERP or home-grown systems.",
  },
  {
    title: "High Availability",
    imageUrl: images.HighAvailability,
    description:
      "Help you keep the server at peak performance. We help you ensure there is no single point of failure or downtime.",
  },
  {
    title: "Staff Augmentation",
    imageUrl: images.StaffAugmentation,
    description:
      "Ease the unwanted headache and pressure to hire and maintain good technical staff in-house.",
  },
  {
    title: "Disaster Recovery",
    imageUrl: images.DisasterRecovery,
    description:
      "Take quick & precise action against hardware, software, infrastructure failures & human errors.",
  },
  {
    title: "Testing",
    imageUrl: images.Testing,
    description:
      "Perform functional testing, data load testing and data validation through automated testing.",
  },
  {
    title: "Training",
    imageUrl: images.Training,
    description:
      "Schedule end-user training sessions — to help ensure a smooth transition and improve user adoption.",
  },
  {
    title: "Monitoring & Support",
    imageUrl: images.MonitoringAndSupport,
    description:
      "Get the right mix of support to troubleshoot various problems and set your team up for success.",
  },
];

const KeyServiceOfferingsSection = () => {
  return (
    <section
      id="data-visualization-solution "
      className="pt-16 pb-16 bg-gray-100 mt-10"
    >
      <div className="container mx-auto">
        <div className="row">
          <div className="col-md-12 text-center">
            <h4 className="section-title text-2xl text-primary">
              Key Service <strong>Offerings</strong>
            </h4>
          </div>
          <div className="col-12">
            <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              {serviceOfferings.map((service, index) => (
                <li
                  key={index}
                  className="mb-6"
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="module-box p-4 bg-white rounded-lg flex flex-col justify-center items-center"
                    style={{ height: "100%" }}
                  >
                    <div className="module-img mb-4">
                      <img
                        src={service.imageUrl}
                        alt={service.title}
                        className="aspect-video object-contain "
                      />
                    </div>
                    <span className="text-center module-title font-bold text-primary lg:tracking-normal tracking-wide">
                      {service.title}
                    </span>
                    <p className="font-sm text-gray-700 text-center lg:tracking-normal tracking-wide">
                      {service.description}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KeyServiceOfferingsSection;
