import React from "react";
import OurServiceCard from "../../../UI/Cards/OurServiceCard/OurServiceCard";
import images from "../../../constants/images";
const ErpSolutions = () => {
  const data = [
    {
      title: "S/4HANA Assessment and Planning",
      description:
        "Enterprises that have made significant investments in SAP ECC are understandably hesitant to embark on another SAP transformation program...",
      link: "/S-4HANA-assessment-and-planning",
      image: images.S4HANAAssessmentAndPlanning,
    },
    {
      title: "S/4HANA Transformation and Implementation",
      description:
        "Leveraging the capabilities of the next-generation SAP S/4HANA business solutions suite...",
      link: "/S-4HANA-transformation-and-Implementation",
      image: images.S4HANATransformationAndImplementation,
    },
    {
      title: "SAP AMS Services",
      description:
        "To stay competitive in today’s dynamic business environment, companies require uninterrupted operation of services for their enterprise applications...",
      link: "/sap-ams-services",
      image: images.SapAmsServices,
    },
    {
      title: "UX and Fiori, App for Training",
      description:
        "Good UX design can influence an organization’s profitability based on standardization, ease of understanding, and access to business processes...",
      link: "/ux-and-fiori-app-for-training",
      image: images.UIandFioriAppForTraining,
    },
    {
      title: "SFDC and SAP integration",
      description:
        "SFDC is world’s leading CRM and being used by many organizations to get the personalised experience of customers expect by using the integrated CRM platform...",
      link: "/sfdc-and-sap-integration",
      image: images.SfdcAndSapIntegration,
    },
    // Add more objects if needed...
  ];
  return (
    <div className="flex justify-center gap-20 items-center flex-wrap pb-8 overflow-hidden">
      <div className="text-center mt-10" data-aos="fade-down">
        <h1 className="text-5xl font-semibold font-share-tech p-2 lg:p-0">
          ERP <span className=" tracking-wide">Solutions SAP</span>
        </h1>
        <p
          className="mt-4  text-gray-700 text-xl font-bold lg:tracking-normal tracking-wide"
          data-aos="fade-up-left"
        >
          Invontec is an IT services company and specialist in SAP and Process
          Automation Engineering, we bring 20+ years of successful SAP expertise
          to help you with
        </p>
      </div>

      {data.map((item) => (
        <OurServiceCard
          key={item.title}
          title={item.title}
          description={item.description}
          image={item.image}
          // animation={i % 2 == 0 ? 'flip-left' : 'flip-right'}
          link={item.link}
        />
      ))}
    </div>
  );
};

export default ErpSolutions;
