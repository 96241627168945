import React from 'react';
import './TransitionCard.css';
const TransitionCard = ({ title, description, icon }) => {
  return (
    <div className="card-client">
      <div className="user-picture">{icon}</div>
      <p className="name-client">
        {title}
        <span>{description}</span>
      </p>
    </div>
  );
};

export default TransitionCard;
