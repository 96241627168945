import React from "react";
import ErpAddOns from "../../Services/components/ErpAddOns";
import BusinessBoost from "../../Services/components/BusinessBoost";
const OurProducts = () => {
  const Product = ({
    direction,
    title,
    // image
  }) => {
    return (
      <div className="p-5">
        <div
          className={`flex items-center justify-center lg:${direction} flex-col lg:px-32 py-4 p-0 border-2 border-separate border-dashed border-black`}
        >
          <div className="w-1/5 h-full flex items-center justify-center">
            <h1 className="tracking-wide text-dynamic">{title}</h1>
          </div>
          <div className="w-4/5">{/* <img src={image} /> */}</div>
        </div>
      </div>
    );
  };
  return (
    <div>
      <div className="flex justify-center w-full items-center">
        <h1>Our Products</h1>
      </div>
      <div className="flex flex-col gap-5">
        <Product
          direction={"flex-row"}
          title={"INSCAN"}
          image={"https://static.imbibetech.com/invontec/assets/cs/cs3.png?1"}
        />
        <Product
          direction={"flex-row-reverse"}
          title={"E-TAPP"}
          image={"https://static.imbibetech.com/invontec/assets/cs/cs4.png?1"}
        />
        <Product
          direction={"flex-row"}
          title={"SIGNIN"}
          image={"https://static.imbibetech.com/invontec/assets/cs/cs5.png"}
        />
      </div>
      <ErpAddOns />
      <BusinessBoost />
    </div>
  );
};

export default OurProducts;
