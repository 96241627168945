import React from "react";
import ContactUs from "../../../../UI/Buttons/ContactUs/ContactUs";
import images from "../../../../constants/images";
const serviceOfferings = [
  {
    title: "ABAP/JAVA/.net/CC+/UI5/Fiori",
    imageUrl: images.AbapJavaNetCCPPUI5,
    description:
      "To help you extend the offering, we create additional features or applications for your product which require certain development skills – for example, a mobile version, BI tool and so on.",
  },
  {
    title: "Power BI, Qlik view",
    imageUrl: images.PowerBiQlikView,
    description:
      "Solution by leveraging data from almost all systems to empower users with an integrated model for self-service analytics without adding complexity, use best in class BI tools like Qlik and Power BI.",
  },
  {
    title: "UI path/ blue prism, Chat Bots, Python",
    imageUrl: images.UIPathBluePrismChatBotsPython,
    description:
      "Solves your specific problems, satisfies your exclusive needs and makes your workflows easier, faster, and more efficient. It includes Web/Mobile/Desktop Apps.",
  },
  {
    title: "Tester",
    imageUrl: images.Tester,
    description:
      "We specialize in providing application testing solutions, setting up Testing Center of Excellence (CoE’s), Niche testing needs on latest tools, QA strategies at optimal cost, desired Quality, and ensure delivery at the right time every time.",
  },
];

const ServiceOfferingsSection = () => {
  return (
    <section id="service-offering" className="pt-16 pb-16 bg-gray-100 mt-10">
      <div className="container mx-auto">
        <div className="row mb-8">
          <div className="col-md-4">
            <h4 className="section-title text-primary">
              <strong>Service</strong> Offerings
            </h4>
            <p className="lg:tracking-normal tracking-wide font-size-14 text-gray-700">
              Professional Tech For Your Business{" "}
              <strong>We can make technology accessible</strong>
            </p>
          </div>
          <div className="col-md-8">
            <p className="lg:tracking-normal tracking-wide font-size-14 text-gray-700">
              We are well-versed in a variety of operating systems, networks,
              and databases. We use this expertise to help our customers with a
              variety of small to mid-sized projects. Invontec delivers high
              caliber information technology solutions to our customers. We do
              not merely apply our available resources rather, we define and
              deliver the resources needed for each project. Our Application
              Development process leverages subject matter expertise to ensure
              projects are customized to meet needs and delivered quickly.
              Invontec takes on the complex challenges of our customers and
              works as a trusted partner to develop innovative solutions in
              support of their missions.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ul
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 "
              style={{ display: "flex", flexWrap: "wrap" }}
            >
              {serviceOfferings.map((service, index) => (
                <li
                  key={index}
                  className="gl-list-item bg-white p-3 rounded-lg "
                  style={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="module-box bg-white flex flex-col justify-end"
                    style={{ height: "100%" }}
                  >
                    <div className="module-img mb-4">
                      <img
                        src={service.imageUrl}
                        alt={service.title}
                        className="aspect-video "
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                    <p className="lg:tracking-normal tracking-wide font-size-14 text-gray-700">
                      <strong>{service.title}</strong>
                      <br />
                      {service.description}
                    </p>
                    <div style={{ marginTop: "auto" }}>
                      <ContactUs />
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceOfferingsSection;
