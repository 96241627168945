import React from "react";
import BusinessBoost from "../../Services/components/BusinessBoost";
import ApplicationManagement from "./components/ApplicationManagement";
import RegionalDevelopment from "./components/RegionalDevelopment";
import ServiceOfferingsSection from "./components/ServicesOfferingsSection";
import Header from "../../../common/components/Header";
import { Helmet } from "react-helmet";

const DevelopmentCenter = () => {
  return (
    <>
      <Helmet>
        <title>Invontec | Devlopment Center</title>
        <link rel="canonical" href="https://www.invontec.com/development-center" />
      </Helmet>
      <div>
        <Header title="Development Center" />
        <div className="mt-10 w-full">
          <ApplicationManagement />
          <RegionalDevelopment />
          <ServiceOfferingsSection />
          <BusinessBoost />
        </div>
      </div>
    </>
  );
};

export default DevelopmentCenter;
