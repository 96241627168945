import React from "react";
import BusinessBoost from "../../../../Services/components/BusinessBoost";
import SfdcService from "./components/SfdcService";
import ServicesSection from "./components/ServicesSection";
import Header from "../../../../../common/components/Header";
import { Helmet } from "react-helmet";

const SfdcAndSap = () => {
  return (
    <>
      <Helmet>
        <title>Invontec | SFDC Services Salesforce Integration</title>
        <link rel="canonical" href="https://www.invontec.com/sfdc-and-sap-integration" />
      </Helmet>
      <div>
        <div className="w-full">
          <Header title="SFDC and SAP Integration" />
        </div>
        <div className="mt-10 w-full">
          <SfdcService />
          <ServicesSection />
          <BusinessBoost />
        </div>
      </div>
    </>
  );
};

export default SfdcAndSap;
