import React from 'react';
import { BiLogoInstagram, BiLogoLinkedin } from 'react-icons/bi';
import { MdEmail} from 'react-icons/md';
import { Link } from 'react-router-dom';
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <section id="footer" className="p-10 text-white bg-blue-950">
      <div className="flex flex-col justify-evenly ">
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
          <div className="col-span-1 md:col-span-1">
            <h4 className="widget-title">Invontec</h4>
            <ul>
              <li>
                <Link className="text-white" to="/about">
                  About Us
                </Link>
              </li>
              {/* <li>
                <Link className="text-white"  title="Leadership" >
                  Our Leaders
                </Link>
              </li> */}
              <li>
                <Link className="text-white" title="Career" to="/services">
                  Our Services
                </Link>
              </li>
              <li>
                <Link className="text-white" title="Career" to="/career">
                  Career
                </Link>
              </li>
              <li>
                <Link
                  className="text-white"
                  title="Contact Us"
                  to="/contact-us"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-1 md:col-span-1">
            <h4 className="widget-title">ERP Solution</h4>
            <ul>
              <li>
                <Link
                  className="text-white"
                  title="ERP Software"
                  to="/erp-solution-sap"
                >
                  SAP
                </Link>
              </li>
              <li>
                <Link
                  className="text-white"
                  to="/S-4HANA-assessment-and-planning"
                >
                  S/4HANA Assessment
                </Link>
              </li>
              <li>
                <Link
                  className="text-white"
                  to="/S-4HANA-transformation-and-Implementation"
                >
                  S/4HANA Transformation
                </Link>
              </li>
              <li>
                <Link className="text-white" to="/sap-ams-services">
                  SAP AMS Services
                </Link>
              </li>
              <li>
                <Link
                  className="text-white"
                  to="/ux-and-fiori-app-for-training"
                >
                  UX and Fiori, App for Training
                </Link>
              </li>
              <li>
                <Link className="text-white" to="/sfdc-and-sap-integration">
                  SFDC and SAP integration
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-span-1 md:col-span-1">
            <h4 className="widget-title">Our Services</h4>
            <ul>
              <li>
                <Link className="text-white" to="/erp-solution-sap">
                  ERP Service
                </Link>
              </li>
              <li>
                <Link className="text-white" to="/automation">
                  Automation / Robotic
                </Link>
              </li>
              <li>
                <Link className="text-white" to="/sfdc">
                  SFDC
                </Link>
              </li>
              <li>
                <Link className="text-white" to="/ams-management">
                  AMS Management
                </Link>
              </li>
              <li>
                <Link className="text-white" to="/development-center">
                  Development Center
                </Link>
              </li>
              <li>
                <Link className="text-white" to="dashboard">
                  Management Dashboard
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-span-1 md:col-span-1">
            <h4 className="widget-title">Products</h4>
            <ul>
              <li>
                <Link className="text-white" to="/app-for-training">
                  eTAPP
                </Link>
              </li>
              <li>
                <Link className="text-white" to="/inscan">
                  Segregation of Duty
                </Link>
              </li>
              <li>
                <Link className="text-white" to="/app-for-event">
                  Event management
                </Link>
              </li>
            </ul>
            <h4 className="widget-title">Companies</h4>
            <ul>
              <li>
                <Link className="text-white"  >
                  India
                </Link>
                <span></span>
              </li>
              <li>
                <Link className="text-white"  >
                  Singapore
                </Link>
              </li>
              {/* <li>
                <Link className="text-white"  >
                  USA
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="col-span-1 md:col-span-1">
            <h4 className="widget-title">Reach Us</h4>
            <ul>
              <li className="">
                <Link
                  to="tomail:info@invontec.com"
                  className="text-white flex gap-2 w-full "
                >
                  <MdEmail size={20} />
                  <span>info@invontec.com</span>
                </Link>
                </li>
              {/* <li className="">
                <Link
                  to="https://instagram.com/invontec_karnal?igshid=NzZhOTFlYzFmZQ=="
                  className="text-white flex gap-2 w-full "
                >
                  <BiLogoInstagram size={20} />
                  <span>invontec Karnal</span>
                </Link>
                
                </li> */}
                <li className="">
                    <a
                      href="https://instagram.com/invontec_karnal?igshid=NzZhOTFlYzFmZQ=="
                      className="text-white flex gap-2 w-full "
                    >
                      <BiLogoInstagram size={20} />
                      
                    </a>
                </li>
                <li className="">
                    <a
                      href="https://www.linkedin.com/company/invontec-consulting/"
                      className="text-white flex gap-2 w-full "
                    >
                      <BiLogoLinkedin size={20} />
                      
                    </a>
                </li>


              {/* <li className="">
                <Link
                  to="tomail:info@invontec.com"
                  className="text-white flex gap-2 w-full "
                >
                  <MdFacebook size={20} />
                  <span>info@invontec.com</span>
                </Link>
                </li> */}
              {/* <li className="">
                <Link
                  to="tomail:info@invontec.com"
                  className="text-white flex gap-2 w-full "
                >
                  <BiLogoLinkedin size={20} />
                  <span>Invontec Infosoft Pvt. Ltd.</span>
                </Link>
                </li> */}
              {/* </li>
              <li>
                <Link

                  to="tel:+919813154549"
                  className="text-white flex gap-2 w-full "
                >
                  <BiPhone />
                  <span> India- +91 9050550567</span>
                </Link>
              </li>
              <li>
                <Link

                  to="tel:+6590021506"
                  className="text-white flex gap-2 w-full "
                >
                  <BiPhone />
                  <span> Singapore- +65 9002 1506</span>
                </Link>
              </li> */}
            </ul>

          </div>
        </div>

        <div className="flex w-full justify-center mt-4">
          <div className="col-span-1">
            {/* Copyright text */}
            <p className="copyright-text">
              Copyright © {currentYear}{' '}
              <Link className="text-white" >
                Invontec.
              </Link>{' '}
              All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
