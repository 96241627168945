import React from 'react';
import OurServiceCard from '../../../../UI/Cards/OurServiceCard/OurServiceCard';
import images from '../../../../constants/images';

const OurServices = () => {
  const data = [
    {
      title: 'ERP Service',
      description:
        'ERP is required to realize business value in the new digital economy. Without modernization, ERPs are open to cyber resilience issues and an impediment to digital transformation.',
      image: images.erp,
      link: '/erp-solution-sap',
    },
    {
      title: 'Automation / Robotic',
      description:
        'Our innovative automation solution redefines operational efficiency by streamlining processes. Experience process optimization without the need for process overhauls.',
      image: images.automation,
      link: '/automation',
    },
    {
      title: 'CRM',
      description:
        'As a trusted Salesforce partner, we bring in deep industry insights, world-class global delivery, and value-driven innovation in designing, implementing, market-leading SFDC solutions.',
      image: images.sfdc,
      link: '/sfdc',
    },
    {
      title: 'AMS Management',
      description:
        'To stay in today’s dynamic business environment, companies require uninterrupted operation of services for their enterprise applications. Operation of services for their enterprise application.',
      image: images.ams,
      link: '/ams-management',
    },
    {
      title: 'Development Center',
      description:
        'Our development center which is statrtegilcy located and support our customer in maintaining QCD. We work with our customer on specific requirements and provide variety of development support.',
      image: images.development,
      link: '/development-center',
    },
    {
      title: 'Management Dashboard',
      description:
        'Invontec consultants bring a combination of deep product expertise, knowledge gained through multiple BI implementations and technical skill sets with a focus on innovation and collaboration.',
      image: images.dashboard,
      link: '/dashboard',
    },

  ];
  return (
    <div className="flex flex-col items-center bg-gray-100 lg:p-10">
      <div className="text-center" data-aos="fade-down">
        <h1 className="text-5xl font-semibold font-share-tech p-2 lg:p-0">
          Our <span className="text-5xl">Services</span>
        </h1>
        <p className="mt-4 font-bold text-gray-700 text-xl lg:tracking-normal tracking-wide" data-aos="fade-up-left">
          Complete end-to-end business automation and technology services to meet your business needs.
        </p>
      </div>
      <div className="mt-10 w-full flex flex-col justify-center items-center gap-20 p-2">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-20 items-center">
          {data.map((item, i) => (
            <OurServiceCard
              key={item.title}
              title={item.title}
              description={item.description}
              image={item.image}
              animation={i % 2 === 0 ? 'flip-left' : 'flip-right'}
              link={item.link}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurServices;
