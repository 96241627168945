import React, { useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../../../apis/api';
import Loader from '../../../../UI/Loader';
const ApplyToJob = ({ jobId }) => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: '',
    resume: null,
  });

  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevData) => ({
      ...prevData,
      resume: file,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setIsLoading(true);
      const user = {
        name: formData.name,
        phone: formData.phone,
        email: formData.email,
        message: formData.message,
      };

      // Append the user object to formData
      formData.user = user;
      formData.jobId = jobId;
      // Handle form submission, including sending the formData with the resume file
      await api.Candidate.createCandidate(formData);
      toast.success('Your resume submitted successfully!');
      setFormData({
        name: '',
        phone: '',
        email: '',
        message: '',
        resume: null,
      });
    } catch (error) {
      if (error?.response?.data) return toast.error(error.response.data.error);
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="p-6 bg-gray-100 flex  justify-center">
      <div>
        <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
          <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
            <div className="lg:col-span-2">
              <h2 className="text-lg font-semibold mb-4">Upload Resume</h2>
              <form onSubmit={handleSubmit}>
                <div className="mb-4">
                  <label htmlFor="name" className="block font-semibold mb-1">
                    Name:
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="border rounded px-2 py-1 w-full"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="phone" className="block font-semibold mb-1">
                    Phone:
                  </label>
                  <input
                    type="text"
                    id="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className="border rounded px-2 py-1 w-full"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="email" className="block font-semibold mb-1">
                    Email:
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="border rounded px-2 py-1 w-full"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="message" className="block font-semibold mb-1">
                    Message:
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="border rounded px-2 py-1 w-full h-20"
                    required
                  />
                </div>
                <div className="mb-4">
                  <label htmlFor="resume" className="block font-semibold mb-1">
                    Resume (PDF):
                  </label>
                  <input
                    type="file"
                    id="resume"
                    name="resume"
                    accept=".pdf"
                    onChange={handleFileChange}
                    className="border rounded py-1 w-full"
                    required
                  />
                </div>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  {isLoading ? <Loader /> : 'Upload'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyToJob;
