import React from "react";
import BusinessBoost from "../../Services/components/BusinessBoost";
import Robotics from "./components/Robotics";
import ServicesSection from "./components/ServicesSection";
import Header from "../../../common/components/Header";
import { Helmet } from "react-helmet";

const AutomationAndRobotics = () => {
  return (
    <>
      <Helmet>
        <title>Invontec | Automation And Robotics</title>
        <link rel="canonical" href="https://www.invontec.com/automation" />
      </Helmet>
      <div className="overflow-hidden">
        <div className="w-full h-20">
          <Header title="Automation and Robotics" />
        </div>
        <div className=" mt-20 w-full">
          <Robotics />
          <ServicesSection />
          <BusinessBoost />
        </div>
      </div>
    </>
  );
};

export default AutomationAndRobotics;
