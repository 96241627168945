import React from "react";
import images from "../../constants/images";
import BusinessForms from "../ContactUs/components/BusinessForms";
import Locations from "./components/Locations";
import { Helmet } from "react-helmet";
const About = () => {
  // const scrollRef = useRef(null);
  // useScrollSnap({ ref: scrollRef, duration: 100, delay: 50 });
  return (
    <>
      <Helmet>
        <title>Invontec | Contact Us</title>
      </Helmet>
      <div className="overflow-hidden">
        <div className="  flex justify-center items-center  flex-col gap-5 overflow-hidden">
          <div className="w-full">
            <img
              src={images.contactUs}
              alt="about"
              className="w-full h-auto"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="500"
              style={{ width: "100%", height: "200px" }}
            />
          </div>

          <div className="flex flex-col items-center  bg-white ">
            <div className="text-center " data-aos="fade-down">
              <h1 className="text-5xl font-semibold font-share-tech mt-4">
                How To <span className=" tracking-wide">Reach Us?</span>
              </h1>
              <p
                className="  text-gray-700 text-xl lg:tracking-normal tracking-wide"
                data-aos="fade-up-left"
              >
                Let’s talk about{" "}
                <strong>innovative solutions, business automation</strong> and
                how we can help you achieve your business goals.
              </p>
            </div>
            <div className="mt-4"></div>
          </div>
          <Locations />
        </div>
        <div className="flex flex-col items-center  bg-white mt-10">
          <div className="text-center " data-aos="fade-down">
            <h1 className="text-5xl font-semibold font-share-tech mt-4 ">
              Take Your Business{" "}
              <span className=" tracking-wide">to the next level</span>
            </h1>
            <p
              className=" font-bold text-gray-700 text-xl lg:tracking-normal tracking-wide"
              data-aos="fade-up-left"
            >
              <strong>Free Consultation. </strong> No Commitments necessary!
            </p>
          </div>
        </div>
        <div className="mt-4"></div>
        <BusinessForms />
      </div>
    </>
  );
};

export default About;
