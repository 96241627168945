import React from 'react';

const UserTable = ({
  users,
  userRef,
  editingUserId,
  handleUserChange,
  handleUserEditClick,
  handleUserSaveClick,
  setEditingUserId,
  setShowUserModal,
}) => {
  const roles = ['admin', 'hr', 'other'];

  return (
    <div className="p-10 w-max">
      <div className="flex w-full gap-10 p-2 items-center">
        <h2 className="text-2xl font-bold ">User Information</h2>
        <button
          onClick={() => setShowUserModal(true)}
          className="px-4 py-2 bg-blue-600 text-white rounded-xl font-bold"
        >
          Add User
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="border-collapse border overflow-x-auto">
          <thead className="bg-gray-200">
            <tr className="border-b">
              <th className="border px-4 py-2">Username</th>
              <th className="border px-4 py-2">Role</th>
              <th className="border px-4 py-2">Action</th>
            </tr>
          </thead>
          <tbody>
            {users.map(
              (user) =>
                userRef.id !== user._id && (
                  <tr key={user._id} className="border-b">
                    <td className="border px-4 py-2">
                      <input
                        value={user.username}
                        readOnly={true}
                        onChange={(e) => {
                          handleUserChange(e, 'username', user._id);
                        }}
                      />
                    </td>
                    <td className=" px-4 py-2">
                      <td className=" px-4 py-2">
                        {editingUserId !== user._id ? (
                          user.role
                        ) : (
                          <select
                            value={user.role}
                            onChange={(e) =>
                              handleUserChange(e, 'role', user._id)
                            }
                            disabled={
                              editingUserId !== user._id &&
                              user.role === 'admin'
                            }
                            className={`${
                              editingUserId !== user._id
                                ? ''
                                : 'border-2 border-black'
                            } capitalize`}
                          >
                            {roles.map((role) => (
                              <option
                                key={role}
                                value={role}
                                className="capitalize"
                              >
                                {role}
                              </option>
                            ))}
                          </select>
                        )}
                      </td>
                    </td>
                    <td className="border px-4 py-2">
                      {editingUserId !== user._id ? (
                        <button
                          onClick={() => handleUserEditClick(user._id)}
                          className="bg-blue-700 px-2 py-1 text-white font-bold rounded-lg"
                        >
                          Edit
                        </button>
                      ) : (
                        <div className="flex gap-2">
                          <button
                            onClick={() => handleUserSaveClick(user)}
                            className="bg-blue-700 px-2 py-1 text-white font-bold rounded-lg"
                          >
                            Save
                          </button>
                          <button
                            onClick={() => setEditingUserId(null)}
                            className="bg-blue-700 px-2 py-1 text-white font-bold rounded-lg"
                          >
                            Cancel
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserTable;
