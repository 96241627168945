import React from 'react';
import images from '../../../../constants/images';
import TwoButtons from '../../../../UI/Buttons/TwoButtons/TwoButtons';

const ApplicationManagement = () => {
  return (
    <div className="pt-16 pb-16 bg-gray-100">
      <div className="container mx-auto">
        <div className="md:flex md:items-center">
          <div className="md:w-1/2 md:p-8">
            <h2 className="lg:tracking-normal tracking-wide text-2xl md:text-3xl font-semibold text-primary mb-4">
              Fast DECISION-MAKING{' '}
              <strong className="secondary-blue-color lg:tracking-normal tracking-wide">
                TO EMPOWER YOUR BUSINESS
              </strong>
            </h2>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-base mb-4">
              Invontec experienced consultants bring a combination of deep
              product expertise, knowledge gained through multiple successful BI
              implementations and not only the technical skill sets but also the
              business insights needed to meet the business requirements and
              goals that only the technology can deliver.
            </p>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-base mb-4">
              Our BI implementation specialists utilize best practices and
              proven agile methodologies that help to make the installation and
              integration of best in class tools like QlikView, Power BI,
              Hadoop, MongoDB, etc.
            </p>

            <TwoButtons />
          </div>
          <div className="md:w-10/12">
            <img
              src={images.power_bi}
              alt="UX And Fiori"
              className="w-full mix-blend-multiply"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationManagement;
