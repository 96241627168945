import React from "react";
import images from "../../../constants/images";
import TalkToExpert from "../../../UI/Buttons/TalkToExpert/TalkToExpert";
import Phone from "../../../UI/Phone/Phone";
import { FiCheckCircle } from "react-icons/fi";
import OurProducts from "../components/OurProducts";
import ViewAllProducts from "../../../UI/Buttons/ViewAllProducts/ViewAllProducts";
import { Helmet } from "react-helmet";
const FirstImages = [
  "https://static.imbibetech.com/invontec/assets/case-study-5/1.png",
  "https://static.imbibetech.com/invontec/assets/case-study-5/2.png",
  "https://static.imbibetech.com/invontec/assets/case-study-5/3.png",
  "https://static.imbibetech.com/invontec/assets/case-study-5/4.png",
];
const ImageFirst = () => {
  return (
    <div className="overflow-hidden">
      <div className="flex justify-center items-center lg:h-full "></div>
      <h1>Event management for service sector - SignIn</h1>
      <div className="flex flex-col justify-center items-center lg:h-full w-full  lg:flex-row">
        <div className="flex justify-center items-center w-full lg:h-full lg:w-1/2 lg:p-10">
          <div className="flex justify-center items-center p-2 ">
            <Phone images={FirstImages} />
          </div>
        </div>
        <div
          className="flex flex-col justify-center items-center lg:w-2/5 lg:flex-col"
          // data-aos="flip-down"
          // data-aos-duration="1500"
        >
          <p className="text-xl   p-5 text-center ">
            Easy Management of booking events , suitable to any small scale to
            large scale business
          </p>
          <p className="text-lg font-bold   p-5 text-center ">Key Features :</p>
          <ul className="list-disc bg-white text-black  font-semibold p-2 rounded-2xl text-base md:text-lg pl-6">
            <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
              <span className="text-primary mr-2">
                <FiCheckCircle />
              </span>
              Access for internal / external customer
            </li>
            <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
              <span className="text-primary mr-2">
                <FiCheckCircle />
              </span>
              Availability checking of various events
            </li>
            <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
              <span className="text-primary mr-2">
                <FiCheckCircle />
              </span>
              Booking of events with Price tag
            </li>
            <li className="lg:tracking-normal tracking-wide mb-3 flex items-center">
              <span className="text-primary mr-2">
                <FiCheckCircle />
              </span>
              Detailed / Summarized Analysis of all bookings
            </li>
          </ul>
          <div className="flex gap-2">
            <ViewAllProducts />
            <TalkToExpert />
          </div>
        </div>
      </div>
    </div>
  );
};

const AppForEvent = () => {
  // const scrollRef = useRef(null);
  // useScrollSnap({ ref: scrollRef, duration: 100, delay: 50 });
  return (
    <>
      <Helmet>
        <title>Invontec | App For Event</title>
        <link rel="canonical" href="https://www.invontec.com/app-for-event" />
      </Helmet>
      <div className="overflow-hidden">
        <div className="  flex justify-center items-center  flex-col gap-5 overflow-hidden">
          <div className="w-full">
            <img
              src={images.aboutBg}
              alt="about"
              className="w-full h-auto"
              data-aos="flip-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="500"
              style={{ width: "100%", height: "200px" }}
            />
          </div>

          <div className="flex flex-col justify-center items-center  ">
            <div className="text-center mt-10" data-aos="fade-down"></div>
            <ImageFirst />
            {/* <TextFirst /> */}
            <OurProducts />
          </div>
          {/* <Umbrella /> */}
        </div>
      </div>
    </>
  );
};

export default AppForEvent;
