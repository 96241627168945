import React from 'react';
import TwoButtons from '../../../../../../UI/Buttons/TwoButtons/TwoButtons';
import images from '../../../../../../constants/images'

const CaseStudySection = () => {
  return (
    <section id="sap-modules" className="pt-16 mt-10 pb-16 bg-white">
      <div className="container mx-auto">
        <div className="text-center">
          <h2 className="section-title secondary-blue-color">
            Case Study: <strong>Hana Migration POC</strong>
          </h2>
        </div>
        <div className="mt-8">
          <div className="module-box">
            <div className="module-img mb-6">
              <img
                src={images.image2}
                alt="Hana Migration POC"
              />
            </div>
          </div>
        </div>
        <div className="text-center mt-8">
          <TwoButtons />
        </div>
      </div>
    </section>
  );
};

export default CaseStudySection;
