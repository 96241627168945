import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import api from "../apis/api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import CreateJob from "./CreateJob";
import UserTable from "./UserTable";
import JobTable from "./JobTable";
import AddUser from "./AddUser";
import MessageList from "./Messages";
import CandidateList from "../Pages/Careers/Jobs/components/CandidateList";
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";

const Info = () => {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  if (!isAuthenticated) return <Navigate to={"/login"} />;
  const [users, setUsers] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [editingUserId, setEditingUserId] = useState(null);
  const [editingJobId, setEditingJobId] = useState(null);
  const [showJobModal, setShowJobModal] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [resumes, setResumes] = useState([]);
  const userRef = useSelector((state) => state.user);

  const fetchUsers = async () => {
    try {
      const res = await api.Auth.users();
      setUsers(res.data);
    } catch (error) {
      if (error?.response?.data) return toast.error(error.response.data.error);
      toast.error(error.message);
    }
  };
  const fetchJobs = async () => {
    try {
      const res = await api.Job.getAllJobs();
      setJobs(res.data);
    } catch (error) {
      if (error?.response?.data) return toast.error(error.response.data.error);
      toast.error(error.message);
    }
  };
  const fetchResumes = async () => {
    try {
      const res = await api.Candidate.getAllCandidates();
      setResumes(res.data);
    } catch (error) {
      if (error?.response?.data) return toast.error(error.response.data.error);
      toast.error(error.message);
    }
  };
  useEffect(() => {
    Promise.all([fetchUsers(), fetchJobs(), fetchResumes()]);
  }, []);

  const handleUserEditClick = (userId) => {
    setEditingUserId(userId);
  };
  const handleJobEditClick = (jobId) => {
    setEditingJobId(jobId);
  };

  const handleUserSaveClick = async (editedUser) => {
    try {
      const updatedUserData = {
        username: editedUser.username,
        role: editedUser.role,
      };

      await api.Auth.updateUser(updatedUserData, editedUser._id); // Send only the updated user data
      toast.success("Updated Successfully!");
      setEditingUserId(null);
      fetchUsers();
    } catch (error) {
      if (error?.response?.data) return toast.error(error.response.data.error);
      toast.error(error.message);
    }
  };
  const handleJobSaveClick = async (editedJob) => {
    try {
      const updatedJobData = {
        title: editedJob.title,
        lastDateToApply: editedJob.lastDateToApply,
        type: editedJob.type,
        minQualification: editedJob.minQualification,
        experience: editedJob.experience,
        location: editedJob.location,
        salary: editedJob.salary,
        isActive: editedJob.isActive,
        description: editedJob.description,
        additionalInformation: editedJob.additionalInformation,
      };

      await api.Job.updateJobById(editedJob._id, updatedJobData); // Send only the updated user data
      toast.success("Updated Successfully!");
      setEditingJobId(null);
      fetchJobs();
    } catch (error) {
      if (error?.response?.data) return toast.error(error.response.data.error);
      toast.error(error.message);
    }
  };

  const handleJobDelete = async (jobId) => {
    try {
      await api.Job.deleteJobById(jobId);
      toast.success("Deleted Successfully!");
      fetchJobs();
    } catch (error) {
      if (error?.response?.data) return toast.error(error.response.data.error);
      toast.error(error.message);
    }
  };
  const handleUserChange = (e, field, userId) => {
    const updatedUsers = users.map((user) =>
      user._id === userId ? { ...user, [field]: e.target.value } : user
    );
    setUsers(updatedUsers);
  };
  const handleJobChange = (e, field, jobId) => {
    const updatedJobs = jobs.map((job) =>
      job._id === jobId ? { ...job, [field]: e.target.value } : job
    );
    setJobs(updatedJobs);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  const toggleDateEditing = () => {
    setEditingDateField(!editingDateField);
  };
  const customStyles = {
    content: {
      top: "50%",
      color: "#000",
      left: "50%",
      transform: "translate(-50%, -50%)",
      // width: '80%',
      borderRadius: "10px",
      height: "max-content",
      padding: "2rem",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      background: "rgba(255, 255, 255, 0.9)",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.7)",
      zIndex: 1000,
    },
  };

  const [editingDateField, setEditingDateField] = useState(false);
  return (
    <>
      <Helmet>
        <title>Invontec | Info</title>
        <link rel="canonical" href="https://www.invontec.com/info" />
      </Helmet>
      <div className="flex flex-col">
        <div className="w-full h-28"></div>
        <div className="flex flex-col">
          {userRef.role === "admin" && (
            <UserTable
              users={users}
              userRef={userRef}
              editingUserId={editingUserId}
              handleUserChange={handleUserChange}
              handleUserEditClick={handleUserEditClick}
              handleUserSaveClick={handleUserSaveClick}
              setEditingUserId={setEditingUserId}
              setShowUserModal={setShowUserModal}
            />
          )}

          {(userRef.role === "admin" || userRef.role === "hr") && (
            <JobTable
              jobs={jobs}
              editingDateField={editingDateField}
              userRef={userRef}
              editingJobId={editingJobId}
              handleJobChange={handleJobChange}
              handleJobEditClick={handleJobEditClick}
              handleJobSaveClick={handleJobSaveClick}
              handleJobDelete={handleJobDelete}
              setEditingJobId={setEditingJobId}
              toggleDateEditing={toggleDateEditing}
              formatDate={formatDate}
              setShowJobModal={setShowJobModal}
            />
          )}
          {(userRef.role === "admin" || userRef.role === "hr") && (
            <CandidateList data={resumes} />
          )}
        </div>
        <MessageList />
        <Modal
          isOpen={showJobModal}
          onRequestClose={() => setShowJobModal(false)}
          style={customStyles}
        >
          <CreateJob
            fetchJobs={fetchJobs}
            setShowJobModal={setShowJobModal}
            showJobModal={showJobModal}
          />
        </Modal>
        <Modal
          isOpen={showUserModal}
          onRequestClose={() => setShowUserModal(false)}
          style={customStyles}
        >
          <AddUser
            fetchUsers={fetchUsers}
            setShowUserModal={setShowUserModal}
            showUserModal={showUserModal}
          />
        </Modal>
      </div>
    </>
  );
};

export default Info;
