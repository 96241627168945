import { createSlice } from '@reduxjs/toolkit';

const formSlice = createSlice({
  name: 'modal',
  initialState: {
    isOpen: false,
    currentFields: [], // Array to store fields for the current button click
    currentButtonClick: '', // Identifier for the current button click
  },
  reducers: {
    openForm: (state) => {
      state.isOpen = true;
    },
    closeForm: (state) => {
      state.isOpen = false;
      state.currentFields = []; // Reset fields when the form is closed
    },
    setFields: (state, action) => {
      state.currentFields = action.payload.fields;
      state.currentButtonClick = action.payload.buttonClick;
    },
    clearFields: (state) => {
      state.currentFields = []; // Clear the fields
      state.currentButtonClick = '';
    },
  },
});

export const { openForm, closeForm, setFields, clearFields } =
  formSlice.actions;

export default formSlice.reducer;
