import React from 'react';
import images from '../../../../constants/images';
import TalkToExpert from '../../../../UI/Buttons/TalkToExpert/TalkToExpert';

const ApplicationManagement = () => {
  return (
    <div className="pt-16 pb-16 bg-gray-100">
      <div className="container mx-auto">
        <div className="md:flex md:items-center">
          <div className="md:w-1/2 md:p-8">
            <h2 className="lg:tracking-normal tracking-wide text-2xl md:text-3xl font-semibold text-primary mb-4">
              Development Center{' '}
              <strong className="secondary-blue-color lg:tracking-normal tracking-wide">
                Growing development team
              </strong>
            </h2>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-base mb-4">
              Our development center which is statrtegilcy located and support
              our customer in maintaining QCD. We work with our customer on
              specific requirements and provide variety of development support .
              Not limited to development, we do provide functional testing
              service and run the test case using defined scenarios by customer.
            </p>
            <p className="lg:tracking-normal tracking-wide text-gray-600 text-base mb-4">
              To stay competitive in today’s dynamic business environment,
              companies require uninterrupted operation of services for their
              enterprise applications. Clients across industries partner with
              Invontec to leverage our robust support and maintenance services
              for delivering long-term stability, maximized ROI and minimized
              total cost of ownership and maintenance.
            </p>

            <div className="mt-6">
              <TalkToExpert />
            </div>
          </div>
          <div className="md:w-10/12">
            <img
              src={images.developmentCenter}
              alt="UX And Fiori"
              className="w-full mix-blend-multiply"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationManagement;
