import React from 'react';

const formatKey = (key) => {
  return key.replace(/([A-Z])/g, ' $1').trim();
};

const JobDetails = ({ job }) => {
  // Filter out fields to exclude
  const filteredJob = Object.keys(job).reduce((filtered, key) => {
    if (
      !['_id', '__v', 'createdAt', 'updatedAt', 'salary', 'isActive'].includes(
        key
      )
    ) {
      filtered[key] = job[key];
    }
    return filtered;
  }, {});

  return (
    <div className="p-6 bg-gray-100 flex items-center justify-center">
      <div className="mx-auto">
        <div>
          <div className="bg-white rounded shadow-lg p-4 px-4 md:p-8 mb-6">
            <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 lg:grid-cols-3">
              <div className="lg:col-span-2">
                <div className="grid gap-4 gap-y-2 text-sm grid-cols-1 md:grid-cols-5 w-full">
                  {Object.keys(filteredJob).map(
                    (key) =>
                      job[key]?.length > 0 && (
                        <div className="md:col-span-5 w-ful l" key={key}>
                          <label htmlFor={key} className="font-semibold">
                            {formatKey(
                              key.charAt(0).toUpperCase() + key.slice(1)
                            )}
                            :
                          </label>
                          {key === 'description' ||
                          key === 'additionalInformation' ? (
                            <textarea
                              name={key}
                              id={key}
                              className="border rounded px-2 py-1 w-full h-48"
                              value={job[key]}
                              readOnly
                            />
                          ) : (
                            <input
                              type="text"
                              name={key}
                              id={key}
                              className="border rounded px-2 py-1 w-full"
                              value={job[key]}
                              readOnly
                            />
                          )}
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobDetails;
